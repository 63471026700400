// Generated by frontend/tools/make-occupations.pl -- DO NOT EDIT.
angular.module("tmr").factory("OccupationData", function() {
    var occupations = [
       [
          "Aboriginal and Torres Strait Islander Education Worker",
          [
             "aboriginal",
             "aides",
             "carers",
             "community",
             "education",
             "islander",
             "personal",
             "service",
             "strait",
             "torres",
             "workers"
          ]
       ],
       [
          "Aboriginal and Torres Strait Islander Health Worker",
          [
             "aboriginal",
             "community",
             "health",
             "islander",
             "personal",
             "service",
             "strait",
             "support",
             "torres",
             "welfare",
             "workers"
          ]
       ],
       [
          "Accommodation and Hospitality Managers",
          [
             "accommodation",
             "hospitality",
             "managers",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Accountant (General)",
          [
             "accountant",
             "accountants",
             "auditors",
             "business",
             "company",
             "general",
             "human",
             "marketing",
             "professionals",
             "resource",
             "secretaries"
          ]
       ],
       [
          "Accounts Clerk",
          [
             "accounting",
             "accounts",
             "administrative",
             "bookkeepers",
             "clerical",
             "clerk",
             "clerks",
             "numerical",
             "workers"
          ]
       ],
       [
          "Actor",
          [
             "actor",
             "actors",
             "arts",
             "dancers",
             "entertainers",
             "media",
             "other",
             "professionals"
          ]
       ],
       [
          "Actors, Dancers and Other Entertainers",
          [
             "actors",
             "arts",
             "dancers",
             "entertainers",
             "media",
             "other",
             "professionals"
          ]
       ],
       [
          "Actuary",
          [
             "actuaries",
             "actuary",
             "business",
             "human",
             "information",
             "marketing",
             "mathematicians",
             "organisation",
             "professionals",
             "resource",
             "statisticians"
          ]
       ],
       [
          "Acupuncturist",
          [
             "acupuncturist",
             "complementary",
             "health",
             "professionals",
             "therapists",
             "therapy"
          ]
       ],
       [
          "Admissions Clerk",
          [
             "administrative",
             "admissions",
             "clerical",
             "clerk",
             "clerks",
             "inquiry",
             "receptionists",
             "workers"
          ]
       ],
       [
          "Advertising Manager",
          [
             "advertising",
             "manager",
             "managers",
             "public",
             "relations",
             "sales",
             "specialist"
          ]
       ],
       [
          "Advertising Specialist",
          [
             "advertising",
             "business",
             "human",
             "marketing",
             "professionals",
             "public",
             "relations",
             "resource",
             "sales",
             "specialist"
          ]
       ],
       [
          "Aeronautical Engineer",
          [
             "aeronautical",
             "design",
             "engineer",
             "engineering",
             "other",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Aeroplane Pilot",
          [
             "aeroplane",
             "air",
             "design",
             "engineering",
             "marine",
             "pilot",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Aged or Disabled Carer",
          [
             "aged",
             "aides",
             "assistants",
             "carer",
             "carers",
             "community",
             "disabled",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Agricultural Consultant",
          [
             "agricultural",
             "consultant",
             "design",
             "engineering",
             "forestry",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Agricultural Engineer",
          [
             "agricultural",
             "design",
             "engineer",
             "engineering",
             "other",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Agricultural Scientist",
          [
             "agricultural",
             "design",
             "engineering",
             "forestry",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientist",
             "scientists",
             "transport"
          ]
       ],
       [
          "Agricultural Technician",
          [
             "agricultural",
             "engineering",
             "ict",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Agricultural and Horticultural Mobile Plant Operator",
          [
             "agricultural",
             "drivers",
             "forestry",
             "horticultural",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Air Traffic Controller",
          [
             "air",
             "controller",
             "design",
             "engineering",
             "marine",
             "professionals",
             "science",
             "traffic",
             "transport"
          ]
       ],
       [
          "Air Transport Professionals",
          [
             "air",
             "design",
             "engineering",
             "marine",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Airconditioning and Mechanical Services Plumber",
          [
             "airconditioning",
             "construction",
             "mechanical",
             "plumber",
             "plumbers",
             "services",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Airconditioning and Refrigeration Mechanic",
          [
             "airconditioning",
             "electronics",
             "electrotechnology",
             "mechanic",
             "refrigeration",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Aircraft Baggage Handler and Airline Ground Crew",
          [
             "aircraft",
             "airline",
             "baggage",
             "crew",
             "drivers",
             "ground",
             "handler",
             "machinery",
             "mobile",
             "operators",
             "other",
             "plant"
          ]
       ],
       [
          "Aircraft Maintenance Engineer (Avionics)",
          [
             "aircraft",
             "automotive",
             "avionics",
             "engineer",
             "engineering",
             "engineers",
             "maintenance",
             "mechanical",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Aircraft Maintenance Engineer (Mechanical)",
          [
             "aircraft",
             "automotive",
             "engineer",
             "engineering",
             "engineers",
             "maintenance",
             "mechanical",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Aircraft Maintenance Engineer (Structures)",
          [
             "aircraft",
             "automotive",
             "engineer",
             "engineering",
             "engineers",
             "maintenance",
             "mechanical",
             "structures",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Aircraft Refueller",
          [
             "aircraft",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "refueller",
             "road",
             "truck"
          ]
       ],
       [
          "Alarm, Security or Surveillance Monitor",
          [
             "alarm",
             "community",
             "guards",
             "monitor",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "surveillance",
             "workers"
          ]
       ],
       [
          "Ambulance Officer",
          [
             "ambulance",
             "community",
             "health",
             "officer",
             "officers",
             "paramedics",
             "personal",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Amusement Centre Manager",
          [
             "amusement",
             "centre",
             "fitness",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service",
             "sports"
          ]
       ],
       [
          "Anaesthetic Technician",
          [
             "agricultural",
             "anaesthetic",
             "engineering",
             "ict",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Anaesthetist",
          [
             "anaesthetist",
             "health",
             "medical",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Analyst Programmer",
          [
             "analyst",
             "analysts",
             "applications",
             "business",
             "ict",
             "professionals",
             "programmer",
             "programmers",
             "software",
             "systems"
          ]
       ],
       [
          "Anatomist or Physiologist",
          [
             "anatomist",
             "design",
             "engineering",
             "life",
             "natural",
             "physical",
             "physiologist",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Animal Attendants and Trainers",
          [
             "animal",
             "attendants",
             "horticultural",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainers",
             "workers"
          ]
       ],
       [
          "Antique Dealer",
          [
             "antique",
             "dealer",
             "hospitality",
             "managers",
             "retail",
             "service"
          ]
       ],
       [
          "Apiarist",
          [
             "apiarist",
             "farm",
             "farmers",
             "livestock",
             "managers"
          ]
       ],
       [
          "Apparel Cutter",
          [
             "apparel",
             "clothing",
             "cutter",
             "footwear",
             "other",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Aquaculture Farmer",
          [
             "aquaculture",
             "farm",
             "farmer",
             "farmers",
             "managers"
          ]
       ],
       [
          "Aquaculture Worker",
          [
             "aquaculture",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "workers"
          ]
       ],
       [
          "Arborist",
          [
             "animal",
             "arborist",
             "gardeners",
             "horticultural",
             "skilled",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Architect",
          [
             "architect",
             "architects",
             "design",
             "designers",
             "engineering",
             "landscape",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Architectural Draftsperson",
          [
             "architectural",
             "building",
             "draftsperson",
             "engineering",
             "ict",
             "science",
             "surveying",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Architectural, Building and Surveying Technicians",
          [
             "architectural",
             "building",
             "engineering",
             "ict",
             "science",
             "surveying",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Archivist",
          [
             "archivist",
             "archivists",
             "business",
             "curators",
             "human",
             "information",
             "managers",
             "marketing",
             "organisation",
             "professionals",
             "records",
             "resource"
          ]
       ],
       [
          "Armoured Car Escort",
          [
             "armoured",
             "car",
             "community",
             "escort",
             "guards",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Art Director (Film, Television or Stage)",
          [
             "art",
             "arts",
             "director",
             "directors",
             "film",
             "media",
             "professionals",
             "radio",
             "stage",
             "television"
          ]
       ],
       [
          "Art Teacher (Private Tuition)",
          [
             "art",
             "education",
             "miscellaneous",
             "private",
             "professionals",
             "teacher",
             "teachers",
             "tuition",
             "tutors"
          ]
       ],
       [
          "Artistic Director",
          [
             "artistic",
             "arts",
             "director",
             "directors",
             "media",
             "presenters",
             "producers",
             "professionals"
          ]
       ],
       [
          "Arts Administrator or Manager",
          [
             "administrator",
             "arts",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "specialist"
          ]
       ],
       [
          "Auctioneer",
          [
             "agents",
             "auctioneer",
             "auctioneers",
             "insurance",
             "representatives",
             "sales",
             "station",
             "stock",
             "workers"
          ]
       ],
       [
          "Audiologist",
          [
             "audiologist",
             "audiologists",
             "health",
             "professionals",
             "speech",
             "therapy"
          ]
       ],
       [
          "Author",
          [
             "arts",
             "author",
             "authors",
             "book",
             "editors",
             "media",
             "professionals",
             "script"
          ]
       ],
       [
          "Autoglazier",
          [
             "accessories",
             "autoglazier",
             "fitters",
             "labourers",
             "miscellaneous",
             "motor",
             "other",
             "parts",
             "vehicle"
          ]
       ],
       [
          "Automobile Drivers",
          [
             "automobile",
             "bus",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road"
          ]
       ],
       [
          "Automotive Electrician",
          [
             "automotive",
             "electrician",
             "electricians",
             "engineering",
             "mechanics",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Backhoe Operator",
          [
             "backhoe",
             "drivers",
             "earthmoving",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Baker",
          [
             "baker",
             "bakers",
             "food",
             "pastrycooks",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Baking Factory Worker",
          [
             "baking",
             "drink",
             "factory",
             "food",
             "labourers",
             "process",
             "workers"
          ]
       ],
       [
          "Bank Worker",
          [
             "administrative",
             "bank",
             "clerical",
             "clerks",
             "financial",
             "insurance",
             "numerical",
             "workers"
          ]
       ],
       [
          "Bar Attendant",
          [
             "attendant",
             "attendants",
             "bar",
             "baristas",
             "community",
             "hospitality",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Bar Useful or Busser",
          [
             "bar",
             "busser",
             "community",
             "hospitality",
             "other",
             "personal",
             "service",
             "useful",
             "workers"
          ]
       ],
       [
          "Barista",
          [
             "attendants",
             "bar",
             "barista",
             "baristas",
             "community",
             "hospitality",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Barrister",
          [
             "barrister",
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Beauty Therapist",
          [
             "beauty",
             "community",
             "personal",
             "service",
             "sports",
             "therapist",
             "travel",
             "workers"
          ]
       ],
       [
          "Bed and Breakfast Operator",
          [
             "accommodation",
             "bed",
             "breakfast",
             "hospitality",
             "managers",
             "operator",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Beef Cattle Farm Worker",
          [
             "beef",
             "cattle",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "workers"
          ]
       ],
       [
          "Beef Cattle Farmer",
          [
             "beef",
             "cattle",
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers"
          ]
       ],
       [
          "Betting Agency Counter Clerk",
          [
             "administrative",
             "agency",
             "betting",
             "clerical",
             "clerk",
             "clerks",
             "counter",
             "office",
             "support",
             "workers"
          ]
       ],
       [
          "Betting Agency Manager",
          [
             "agency",
             "betting",
             "hospitality",
             "manager",
             "managers",
             "retail",
             "service"
          ]
       ],
       [
          "Betting Clerks",
          [
             "administrative",
             "betting",
             "clerical",
             "clerks",
             "office",
             "support",
             "workers"
          ]
       ],
       [
          "Bicycle Mechanic",
          [
             "bicycle",
             "labourers",
             "mechanic",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Biochemist",
          [
             "biochemist",
             "design",
             "engineering",
             "life",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Biomedical Engineer",
          [
             "biomedical",
             "design",
             "engineer",
             "engineering",
             "other",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Biotechnologist",
          [
             "biotechnologist",
             "design",
             "engineering",
             "life",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Blacksmith",
          [
             "automotive",
             "blacksmith",
             "casting",
             "engineering",
             "fabrication",
             "finishing",
             "forging",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Boarding Kennel or Cattery Operator",
          [
             "boarding",
             "cattery",
             "hospitality",
             "kennel",
             "managers",
             "miscellaneous",
             "operator",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Boat Builder and Repairer",
          [
             "boat",
             "builder",
             "builders",
             "miscellaneous",
             "other",
             "repairer",
             "shipwrights",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Body Artist",
          [
             "artist",
             "body",
             "community",
             "other",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Boiler or Engine Operator",
          [
             "boiler",
             "drivers",
             "engine",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "stationary"
          ]
       ],
       [
          "Book or Script Editor",
          [
             "arts",
             "authors",
             "book",
             "editor",
             "editors",
             "media",
             "professionals",
             "script"
          ]
       ],
       [
          "Bookkeeper",
          [
             "accounting",
             "administrative",
             "bookkeeper",
             "bookkeepers",
             "clerical",
             "clerks",
             "numerical",
             "workers"
          ]
       ],
       [
          "Bookmaker",
          [
             "administrative",
             "bookmaker",
             "clerical",
             "clerks",
             "financial",
             "insurance",
             "market",
             "money",
             "numerical",
             "statistical",
             "workers"
          ]
       ],
       [
          "Bookmaker's Clerk",
          [
             "administrative",
             "betting",
             "bookmakers",
             "clerical",
             "clerk",
             "clerks",
             "office",
             "support",
             "workers"
          ]
       ],
       [
          "Botanist",
          [
             "botanist",
             "design",
             "engineering",
             "life",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Brewery Worker",
          [
             "brewery",
             "drink",
             "factory",
             "food",
             "labourers",
             "process",
             "workers"
          ]
       ],
       [
          "Bricklayer",
          [
             "bricklayer",
             "bricklayers",
             "carpenters",
             "construction",
             "joiners",
             "stonemasons",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Broadcast Transmitter Operator",
          [
             "arts",
             "broadcast",
             "miscellaneous",
             "operator",
             "other",
             "performing",
             "technicians",
             "trades",
             "transmitter",
             "workers"
          ]
       ],
       [
          "Builder's Labourer",
          [
             "builders",
             "building",
             "construction",
             "labourer",
             "labourers",
             "mining",
             "plumbing"
          ]
       ],
       [
          "Building Associate",
          [
             "architectural",
             "associate",
             "building",
             "engineering",
             "ict",
             "science",
             "surveying",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Building Inspector",
          [
             "architectural",
             "building",
             "engineering",
             "ict",
             "inspector",
             "science",
             "surveying",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Building Insulation Installer",
          [
             "building",
             "construction",
             "home",
             "improvement",
             "installer",
             "installers",
             "insulation",
             "labourers",
             "mining"
          ]
       ],
       [
          "Building and Engineering Technicians",
          [
             "building",
             "engineering",
             "ict",
             "other",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Bulk Materials Handling Plant Operator",
          [
             "bulk",
             "drivers",
             "handling",
             "machine",
             "machinery",
             "materials",
             "operator",
             "operators",
             "other",
             "plant",
             "stationary"
          ]
       ],
       [
          "Bulldozer Operator",
          [
             "bulldozer",
             "drivers",
             "earthmoving",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Bungy Jump Master",
          [
             "adventure",
             "bungy",
             "community",
             "fitness",
             "guides",
             "jump",
             "master",
             "outdoor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Bus Driver",
          [
             "automobile",
             "bus",
             "coach",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road"
          ]
       ],
       [
          "Business Broker",
          [
             "agents",
             "broker",
             "business",
             "estate",
             "real",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Business Machine Mechanic",
          [
             "business",
             "electronics",
             "electrotechnology",
             "machine",
             "mechanic",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Butcher or Smallgoods Maker",
          [
             "butcher",
             "food",
             "maker",
             "smallgoods",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Cabinetmaker",
          [
             "cabinetmaker",
             "other",
             "technicians",
             "trades",
             "wood",
             "workers"
          ]
       ],
       [
          "Cabler (Data and Telecommunications)",
          [
             "cabler",
             "data",
             "electronics",
             "electrotechnology",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Cafe Worker",
          [
             "cafe",
             "community",
             "hospitality",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Cafe or Restaurant Manager",
          [
             "accommodation",
             "cafe",
             "hospitality",
             "manager",
             "managers",
             "restaurant",
             "retail",
             "service"
          ]
       ],
       [
          "Call or Contact Centre Manager",
          [
             "call",
             "centre",
             "contact",
             "customer",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service"
          ]
       ],
       [
          "Call or Contact Centre Operator",
          [
             "administrative",
             "call",
             "centre",
             "clerical",
             "clerks",
             "contact",
             "information",
             "inquiry",
             "operator",
             "receptionists",
             "workers"
          ]
       ],
       [
          "Call or Contact Centre Team Leader",
          [
             "administrative",
             "call",
             "centre",
             "clerical",
             "clerks",
             "contact",
             "information",
             "inquiry",
             "leader",
             "receptionists",
             "team",
             "workers"
          ]
       ],
       [
          "Camera Operator (Film, Television or Video)",
          [
             "arts",
             "camera",
             "film",
             "miscellaneous",
             "operator",
             "other",
             "performing",
             "technicians",
             "television",
             "trades",
             "video",
             "workers"
          ]
       ],
       [
          "Canvas Goods Fabricator",
          [
             "canvas",
             "clothing",
             "fabricator",
             "footwear",
             "goods",
             "leather",
             "makers",
             "other",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Car Detailer",
          [
             "car",
             "cleaners",
             "detailer",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Car Park Attendant",
          [
             "attendant",
             "car",
             "labourers",
             "miscellaneous",
             "other",
             "park"
          ]
       ],
       [
          "Caravan Park and Camping Ground Manager",
          [
             "accommodation",
             "camping",
             "caravan",
             "ground",
             "hospitality",
             "manager",
             "managers",
             "park",
             "retail",
             "service"
          ]
       ],
       [
          "Cardiac Technician",
          [
             "agricultural",
             "cardiac",
             "engineering",
             "ict",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Cardiologist",
          [
             "cardiologist",
             "health",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Cardiothoracic Surgeon",
          [
             "cardiothoracic",
             "health",
             "medical",
             "practitioners",
             "professionals",
             "surgeons"
          ]
       ],
       [
          "Careers Counsellor",
          [
             "careers",
             "counsellor",
             "counsellors",
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Caretaker",
          [
             "caretaker",
             "labourers",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Carpenter",
          [
             "bricklayers",
             "carpenter",
             "carpenters",
             "construction",
             "joiners",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Carpenter and Joiner",
          [
             "bricklayers",
             "carpenter",
             "carpenters",
             "construction",
             "joiner",
             "joiners",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Carpet Cleaner",
          [
             "carpet",
             "cleaner",
             "cleaners",
             "labourers",
             "laundry",
             "other",
             "workers"
          ]
       ],
       [
          "Cartographer",
          [
             "architects",
             "cartographer",
             "design",
             "designers",
             "engineering",
             "planners",
             "professionals",
             "science",
             "scientists",
             "spatial",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Cash Van Salesperson",
          [
             "assistants",
             "cash",
             "related",
             "sales",
             "salesperson",
             "salespersons",
             "street",
             "van",
             "vendors",
             "workers"
          ]
       ],
       [
          "Cement Production Plant Operator",
          [
             "cement",
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "production",
             "stationary"
          ]
       ],
       [
          "Cement and Concrete Plant Worker",
          [
             "cement",
             "concrete",
             "factory",
             "labourers",
             "miscellaneous",
             "other",
             "plant",
             "process",
             "workers"
          ]
       ],
       [
          "Charter and Tour Bus Driver",
          [
             "automobile",
             "bus",
             "charter",
             "coach",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road",
             "tour"
          ]
       ],
       [
          "Chauffeur",
          [
             "automobile",
             "bus",
             "chauffeur",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road"
          ]
       ],
       [
          "Checkout Operator",
          [
             "cashiers",
             "checkout",
             "office",
             "operator",
             "operators",
             "sales",
             "support",
             "workers"
          ]
       ],
       [
          "Chef",
          [
             "chef",
             "food",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Chemical Engineer",
          [
             "chemical",
             "design",
             "engineer",
             "engineering",
             "engineers",
             "materials",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Chemical Plant Operator",
          [
             "chemical",
             "gas",
             "generation",
             "miscellaneous",
             "operator",
             "operators",
             "other",
             "petroleum",
             "plant",
             "power",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Chemical Plant Worker",
          [
             "chemical",
             "factory",
             "labourers",
             "miscellaneous",
             "other",
             "plant",
             "process",
             "workers"
          ]
       ],
       [
          "Chemical Production Machine Operator",
          [
             "chemical",
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "production",
             "stationary"
          ]
       ],
       [
          "Chemist",
          [
             "chemist",
             "chemists",
             "design",
             "engineering",
             "food",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport",
             "wine"
          ]
       ],
       [
          "Chemistry Technician",
          [
             "agricultural",
             "chemistry",
             "engineering",
             "ict",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Chief Executive or Managing Director",
          [
             "chief",
             "director",
             "executive",
             "executives",
             "general",
             "legislators",
             "managers",
             "managing"
          ]
       ],
       [
          "Chief Information Officer",
          [
             "chief",
             "ict",
             "information",
             "managers",
             "officer",
             "specialist"
          ]
       ],
       [
          "Child Care Centre Manager",
          [
             "care",
             "centre",
             "child",
             "education",
             "health",
             "manager",
             "managers",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Child Care Worker",
          [
             "aides",
             "care",
             "carers",
             "child",
             "community",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Child or Youth Residential Care Assistant",
          [
             "aides",
             "assistant",
             "assistants",
             "care",
             "carers",
             "child",
             "community",
             "personal",
             "residential",
             "service",
             "special",
             "workers",
             "youth"
          ]
       ],
       [
          "Chiropractor",
          [
             "chiropractor",
             "chiropractors",
             "health",
             "osteopaths",
             "professionals",
             "therapy"
          ]
       ],
       [
          "Chocolate Packer",
          [
             "assemblers",
             "chocolate",
             "factory",
             "labourers",
             "packer",
             "packers",
             "process",
             "product",
             "workers"
          ]
       ],
       [
          "Cinema or Theatre Manager",
          [
             "cinema",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "retail",
             "service",
             "theatre"
          ]
       ],
       [
          "Civil Celebrant",
          [
             "celebrant",
             "civil",
             "community",
             "other",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Civil Engineer",
          [
             "civil",
             "design",
             "engineer",
             "engineering",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Civil Engineering Draftsperson",
          [
             "building",
             "civil",
             "draftsperson",
             "draftspersons",
             "engineering",
             "ict",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Civil Engineering Technician",
          [
             "building",
             "civil",
             "draftspersons",
             "engineering",
             "ict",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Classified Advertising Clerk",
          [
             "administrative",
             "advertising",
             "classified",
             "clerical",
             "clerk",
             "office",
             "other",
             "support",
             "workers"
          ]
       ],
       [
          "Clay Processing Factory Worker",
          [
             "clay",
             "factory",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "processing",
             "workers"
          ]
       ],
       [
          "Clay Products Machine Operator",
          [
             "clay",
             "concrete",
             "drivers",
             "glass",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "processing",
             "products",
             "stationary",
             "stone"
          ]
       ],
       [
          "Clay, Concrete, Glass and Stone Processing Machine Operators",
          [
             "clay",
             "concrete",
             "drivers",
             "glass",
             "machine",
             "machinery",
             "operators",
             "plant",
             "processing",
             "stationary",
             "stone"
          ]
       ],
       [
          "Cleaners",
          [
             "cleaners",
             "labourers",
             "laundry",
             "other",
             "workers"
          ]
       ],
       [
          "Clerical and Administrative Workers",
          [
             "administrative",
             "clerical",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Clerical and Office Support Workers",
          [
             "administrative",
             "clerical",
             "office",
             "other",
             "support",
             "workers"
          ]
       ],
       [
          "Clerk of Court",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "court",
             "legal",
             "miscellaneous",
             "of",
             "other",
             "workers"
          ]
       ],
       [
          "Clinical Coder",
          [
             "administrative",
             "clerical",
             "clinical",
             "coder",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Clinical Haematologist",
          [
             "clinical",
             "haematologist",
             "health",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Clinical Psychologist",
          [
             "clinical",
             "legal",
             "professionals",
             "psychologist",
             "psychologists",
             "social",
             "welfare"
          ]
       ],
       [
          "Clothing Patternmaker",
          [
             "clothing",
             "footwear",
             "other",
             "patternmaker",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Clothing Trades Workers",
          [
             "clothing",
             "footwear",
             "other",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Commercial Cleaner",
          [
             "cleaner",
             "cleaners",
             "commercial",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Commercial Housekeeper",
          [
             "cleaners",
             "commercial",
             "housekeeper",
             "housekeepers",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Commissioned Defence Force Officer",
          [
             "commissioned",
             "defence",
             "force",
             "management",
             "managers",
             "miscellaneous",
             "officer",
             "officers",
             "specialist"
          ]
       ],
       [
          "Commissioned Fire Officer",
          [
             "commissioned",
             "fire",
             "management",
             "managers",
             "miscellaneous",
             "officer",
             "officers",
             "specialist"
          ]
       ],
       [
          "Commissioned Police Officer",
          [
             "commissioned",
             "management",
             "managers",
             "miscellaneous",
             "officer",
             "officers",
             "police",
             "specialist"
          ]
       ],
       [
          "Commodities Trader",
          [
             "advisers",
             "brokers",
             "business",
             "commodities",
             "dealers",
             "financial",
             "human",
             "investment",
             "marketing",
             "professionals",
             "resource",
             "trader"
          ]
       ],
       [
          "Communications Operator",
          [
             "communications",
             "electronics",
             "electrotechnology",
             "operator",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Community Arts Worker",
          [
             "arts",
             "community",
             "legal",
             "professionals",
             "recreation",
             "social",
             "welfare",
             "workers"
          ]
       ],
       [
          "Community Worker",
          [
             "community",
             "health",
             "personal",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Company Secretary",
          [
             "accountants",
             "auditors",
             "business",
             "company",
             "corporate",
             "human",
             "marketing",
             "professionals",
             "resource",
             "secretaries",
             "secretary",
             "treasurers"
          ]
       ],
       [
          "Complementary Health Therapists",
          [
             "complementary",
             "health",
             "professionals",
             "therapists",
             "therapy"
          ]
       ],
       [
          "Composer",
          [
             "arts",
             "composer",
             "media",
             "music",
             "professionals"
          ]
       ],
       [
          "Computer Network and Systems Engineer",
          [
             "computer",
             "engineer",
             "ict",
             "network",
             "professionals",
             "support",
             "systems"
          ]
       ],
       [
          "Concrete Batching Plant Operator",
          [
             "batching",
             "concrete",
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "stationary"
          ]
       ],
       [
          "Concrete Products Machine Operator",
          [
             "clay",
             "concrete",
             "drivers",
             "glass",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "processing",
             "products",
             "stationary",
             "stone"
          ]
       ],
       [
          "Concrete Pump Operator",
          [
             "concrete",
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "pump",
             "stationary"
          ]
       ],
       [
          "Concreter",
          [
             "concreter",
             "construction",
             "labourers",
             "mining"
          ]
       ],
       [
          "Confectionery Maker",
          [
             "confectionery",
             "drink",
             "factory",
             "food",
             "labourers",
             "maker",
             "process",
             "workers"
          ]
       ],
       [
          "Conference and Event Organiser",
          [
             "conference",
             "event",
             "hospitality",
             "managers",
             "miscellaneous",
             "organiser",
             "retail",
             "service"
          ]
       ],
       [
          "Conservation Officer",
          [
             "conservation",
             "design",
             "engineering",
             "environmental",
             "natural",
             "officer",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Conservator",
          [
             "conservator",
             "design",
             "engineering",
             "natural",
             "other",
             "physical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Construction Estimator",
          [
             "architectural",
             "building",
             "construction",
             "engineering",
             "estimator",
             "ict",
             "science",
             "surveying",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Construction Project Manager",
          [
             "construction",
             "distribution",
             "manager",
             "managers",
             "production",
             "project",
             "specialist"
          ]
       ],
       [
          "Construction Rigger",
          [
             "construction",
             "labourers",
             "mining",
             "rigger",
             "steel",
             "structural",
             "workers"
          ]
       ],
       [
          "Container Filler",
          [
             "assemblers",
             "container",
             "factory",
             "filler",
             "labourers",
             "packers",
             "process",
             "product",
             "workers"
          ]
       ],
       [
          "Contract Administrator",
          [
             "administrative",
             "administrator",
             "administrators",
             "clerical",
             "contract",
             "managers",
             "office",
             "program",
             "project",
             "workers"
          ]
       ],
       [
          "Conveyancer",
          [
             "administrative",
             "clerical",
             "conveyancer",
             "conveyancers",
             "executives",
             "legal",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Cook",
          [
             "cook",
             "food",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Copywriter",
          [
             "arts",
             "copywriter",
             "journalists",
             "media",
             "other",
             "professionals",
             "writers"
          ]
       ],
       [
          "Corporate General Manager",
          [
             "chief",
             "corporate",
             "executives",
             "general",
             "legislators",
             "managers"
          ]
       ],
       [
          "Corporate Services Manager",
          [
             "administration",
             "business",
             "corporate",
             "manager",
             "managers",
             "services",
             "specialist"
          ]
       ],
       [
          "Corporate Treasurer",
          [
             "accountants",
             "auditors",
             "business",
             "company",
             "corporate",
             "human",
             "marketing",
             "professionals",
             "resource",
             "secretaries",
             "treasurers"
          ]
       ],
       [
          "Cost Clerk",
          [
             "accounting",
             "administrative",
             "bookkeepers",
             "clerical",
             "clerk",
             "clerks",
             "cost",
             "numerical",
             "workers"
          ]
       ],
       [
          "Cotton Grower",
          [
             "cotton",
             "crop",
             "farm",
             "farmers",
             "grower",
             "managers"
          ]
       ],
       [
          "Counsellors",
          [
             "counsellors",
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Courier",
          [
             "administrative",
             "clerical",
             "courier",
             "couriers",
             "deliverers",
             "office",
             "postal",
             "support",
             "workers"
          ]
       ],
       [
          "Court Bailiff or Sheriff",
          [
             "administrative",
             "bailiff",
             "clerical",
             "clerks",
             "court",
             "legal",
             "miscellaneous",
             "other",
             "sheriff",
             "workers"
          ]
       ],
       [
          "Court Orderly",
          [
             "administrative",
             "clerical",
             "clerks",
             "court",
             "legal",
             "miscellaneous",
             "orderly",
             "other",
             "workers"
          ]
       ],
       [
          "Crane Chaser",
          [
             "chaser",
             "construction",
             "crane",
             "labourers",
             "mining",
             "other"
          ]
       ],
       [
          "Crane, Hoist or Lift Operator",
          [
             "crane",
             "drivers",
             "hoist",
             "lift",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "stationary"
          ]
       ],
       [
          "Credit or Loans Officer",
          [
             "administrative",
             "clerical",
             "clerks",
             "credit",
             "financial",
             "insurance",
             "loans",
             "numerical",
             "officer",
             "workers"
          ]
       ],
       [
          "Crop Farm Workers",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "workers"
          ]
       ],
       [
          "Crop Farmers",
          [
             "crop",
             "farm",
             "farmers",
             "managers"
          ]
       ],
       [
          "Crossing Supervisor",
          [
             "crossing",
             "labourers",
             "miscellaneous",
             "other",
             "supervisor"
          ]
       ],
       [
          "Crowd Controller",
          [
             "community",
             "controller",
             "crowd",
             "guards",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Customer Service Manager",
          [
             "call",
             "centre",
             "contact",
             "customer",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service"
          ]
       ],
       [
          "Customs Officer",
          [
             "administrative",
             "clerical",
             "customs",
             "inspectors",
             "miscellaneous",
             "officer",
             "officers",
             "other",
             "regulatory",
             "workers"
          ]
       ],
       [
          "Dairy Cattle Farm Worker",
          [
             "cattle",
             "dairy",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "workers"
          ]
       ],
       [
          "Dairy Cattle Farmer",
          [
             "cattle",
             "dairy",
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers"
          ]
       ],
       [
          "Dairy Products Maker",
          [
             "dairy",
             "drink",
             "factory",
             "food",
             "labourers",
             "maker",
             "process",
             "products",
             "workers"
          ]
       ],
       [
          "Dance Teacher (Private Tuition)",
          [
             "dance",
             "education",
             "miscellaneous",
             "private",
             "professionals",
             "teacher",
             "teachers",
             "tuition",
             "tutors"
          ]
       ],
       [
          "Dancer or Choreographer",
          [
             "actors",
             "arts",
             "choreographer",
             "dancer",
             "dancers",
             "entertainers",
             "media",
             "other",
             "professionals"
          ]
       ],
       [
          "Data Entry Operator",
          [
             "administrative",
             "clerical",
             "data",
             "entry",
             "general",
             "keyboard",
             "operator",
             "operators",
             "workers"
          ]
       ],
       [
          "Database Administrator",
          [
             "administrator",
             "administrators",
             "database",
             "ict",
             "professionals",
             "security",
             "specialists",
             "systems"
          ]
       ],
       [
          "Debt Collector",
          [
             "administrative",
             "clerical",
             "collector",
             "debt",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Deck Hand",
          [
             "deck",
             "fishing",
             "hand",
             "hands",
             "labourers",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Deer Farmer",
          [
             "deer",
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers"
          ]
       ],
       [
          "Defence Force Member - Other Ranks",
          [
             "community",
             "defence",
             "fighters",
             "fire",
             "force",
             "member",
             "members",
             "other",
             "personal",
             "police",
             "protective",
             "ranks",
             "service",
             "workers"
          ]
       ],
       [
          "Defence Force Senior Officer",
          [
             "chief",
             "defence",
             "executives",
             "force",
             "general",
             "legislators",
             "managers",
             "officer",
             "senior"
          ]
       ],
       [
          "Delivery Driver",
          [
             "delivery",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road"
          ]
       ],
       [
          "Dental Assistant",
          [
             "aides",
             "assistant",
             "assistants",
             "carers",
             "community",
             "dental",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Dental Hygienist",
          [
             "community",
             "dental",
             "health",
             "hygienist",
             "hygienists",
             "personal",
             "service",
             "support",
             "technicians",
             "therapists",
             "welfare",
             "workers"
          ]
       ],
       [
          "Dental Prosthetist",
          [
             "community",
             "dental",
             "health",
             "hygienists",
             "personal",
             "prosthetist",
             "service",
             "support",
             "technicians",
             "therapists",
             "welfare",
             "workers"
          ]
       ],
       [
          "Dental Specialist",
          [
             "dental",
             "health",
             "practitioners",
             "professionals",
             "specialist",
             "therapy"
          ]
       ],
       [
          "Dental Technician",
          [
             "community",
             "dental",
             "health",
             "hygienists",
             "personal",
             "service",
             "support",
             "technician",
             "technicians",
             "therapists",
             "welfare",
             "workers"
          ]
       ],
       [
          "Dental Therapist",
          [
             "community",
             "dental",
             "health",
             "hygienists",
             "personal",
             "service",
             "support",
             "technicians",
             "therapist",
             "therapists",
             "welfare",
             "workers"
          ]
       ],
       [
          "Dentist",
          [
             "dental",
             "dentist",
             "health",
             "practitioners",
             "professionals",
             "therapy"
          ]
       ],
       [
          "Dermatologist",
          [
             "dermatologist",
             "health",
             "medical",
             "other",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Despatching and Receiving Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "despatch",
             "despatching",
             "logistics",
             "other",
             "receiving",
             "transport",
             "workers"
          ]
       ],
       [
          "Detective",
          [
             "community",
             "defence",
             "detective",
             "fighters",
             "fire",
             "force",
             "members",
             "personal",
             "police",
             "protective",
             "service",
             "workers"
          ]
       ],
       [
          "Developer Programmer",
          [
             "analysts",
             "applications",
             "business",
             "developer",
             "ict",
             "professionals",
             "programmer",
             "programmers",
             "software",
             "systems"
          ]
       ],
       [
          "Diagnostic and Interventional Radiologist",
          [
             "diagnostic",
             "health",
             "interventional",
             "medical",
             "other",
             "practitioners",
             "professionals",
             "radiologist"
          ]
       ],
       [
          "Diesel Motor Mechanic",
          [
             "automotive",
             "diesel",
             "electricians",
             "engineering",
             "mechanic",
             "mechanics",
             "motor",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Dietitian",
          [
             "diagnostic",
             "dietitian",
             "health",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Director (Film, Television, Radio or Stage)",
          [
             "arts",
             "director",
             "directors",
             "film",
             "media",
             "professionals",
             "radio",
             "stage",
             "television"
          ]
       ],
       [
          "Director of Photography",
          [
             "arts",
             "director",
             "directors",
             "film",
             "media",
             "of",
             "photography",
             "professionals",
             "radio",
             "stage",
             "television"
          ]
       ],
       [
          "Disabilities Services Officer",
          [
             "community",
             "disabilities",
             "health",
             "officer",
             "personal",
             "service",
             "services",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Diver",
          [
             "diver",
             "miscellaneous",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Diversional Therapist",
          [
             "community",
             "diversional",
             "health",
             "personal",
             "service",
             "support",
             "therapist",
             "welfare",
             "workers"
          ]
       ],
       [
          "Diving Instructor (Open Water)",
          [
             "coaches",
             "community",
             "diving",
             "fitness",
             "instructor",
             "instructors",
             "officials",
             "open",
             "personal",
             "service",
             "sports",
             "water",
             "workers"
          ]
       ],
       [
          "Dog Handler or Trainer",
          [
             "animal",
             "attendants",
             "dog",
             "handler",
             "horticultural",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainer",
             "trainers",
             "workers"
          ]
       ],
       [
          "Dog or Horse Racing Official",
          [
             "coaches",
             "community",
             "dog",
             "fitness",
             "horse",
             "instructors",
             "official",
             "officials",
             "personal",
             "racing",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Domestic Cleaner",
          [
             "cleaner",
             "cleaners",
             "domestic",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Domestic Housekeeper",
          [
             "cleaners",
             "domestic",
             "housekeeper",
             "housekeepers",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Door-to-door Salesperson",
          [
             "assistants",
             "doortodoor",
             "related",
             "sales",
             "salesperson",
             "salespersons",
             "street",
             "vendors",
             "workers"
          ]
       ],
       [
          "Doorperson or Luggage Porter",
          [
             "community",
             "doorperson",
             "hospitality",
             "luggage",
             "other",
             "personal",
             "porter",
             "service",
             "workers"
          ]
       ],
       [
          "Drainage, Sewerage and Stormwater Labourer",
          [
             "building",
             "construction",
             "drainage",
             "labourer",
             "labourers",
             "mining",
             "plumbing",
             "sewerage",
             "stormwater"
          ]
       ],
       [
          "Drainer",
          [
             "construction",
             "drainer",
             "plumbers",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Drama Teacher (Private Tuition)",
          [
             "drama",
             "education",
             "miscellaneous",
             "private",
             "professionals",
             "teacher",
             "teachers",
             "tuition",
             "tutors"
          ]
       ],
       [
          "Dressmaker or Tailor",
          [
             "clothing",
             "dressmaker",
             "footwear",
             "other",
             "tailor",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Driller",
          [
             "driller",
             "drillers",
             "drivers",
             "firers",
             "machine",
             "machinery",
             "miners",
             "operators",
             "plant",
             "shot",
             "stationary"
          ]
       ],
       [
          "Driller's Assistant",
          [
             "assistant",
             "construction",
             "drillers",
             "labourers",
             "mining",
             "other"
          ]
       ],
       [
          "Driving Instructor",
          [
             "community",
             "driving",
             "instructor",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Drug and Alcohol Counsellor",
          [
             "alcohol",
             "counsellor",
             "counsellors",
             "drug",
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Drycleaner",
          [
             "cleaners",
             "drycleaner",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Early Childhood (Pre-primary School) Teacher",
          [
             "childhood",
             "early",
             "education",
             "preprimary",
             "professionals",
             "school",
             "teachers"
          ]
       ],
       [
          "Earth Science Technician",
          [
             "agricultural",
             "earth",
             "engineering",
             "ict",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Earthmoving Labourer",
          [
             "building",
             "construction",
             "earthmoving",
             "labourer",
             "labourers",
             "mining",
             "plumbing"
          ]
       ],
       [
          "Earthmoving Plant Operator (General)",
          [
             "drivers",
             "earthmoving",
             "general",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Economist",
          [
             "business",
             "economist",
             "human",
             "information",
             "marketing",
             "organisation",
             "professionals",
             "resource"
          ]
       ],
       [
          "Education Adviser",
          [
             "adviser",
             "advisers",
             "education",
             "miscellaneous",
             "professionals",
             "reviewers"
          ]
       ],
       [
          "Education Managers",
          [
             "education",
             "health",
             "managers",
             "other",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Education Reviewer",
          [
             "advisers",
             "education",
             "miscellaneous",
             "professionals",
             "reviewers"
          ]
       ],
       [
          "Educational Psychologist",
          [
             "educational",
             "legal",
             "professionals",
             "psychologist",
             "psychologists",
             "social",
             "welfare"
          ]
       ],
       [
          "Electorate Officer",
          [
             "business",
             "electorate",
             "human",
             "information",
             "marketing",
             "officer",
             "organisation",
             "other",
             "professionals",
             "resource"
          ]
       ],
       [
          "Electrical Engineer",
          [
             "design",
             "electrical",
             "engineer",
             "engineering",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Electrical Engineering Draftsperson",
          [
             "building",
             "draftsperson",
             "draftspersons",
             "electrical",
             "engineering",
             "ict",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Electrical Engineering Technician",
          [
             "building",
             "draftspersons",
             "electrical",
             "engineering",
             "ict",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Electrical Linesworker",
          [
             "distribution",
             "electrical",
             "electronics",
             "electrotechnology",
             "linesworker",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Electrical or Telecommunications Trades Assistant",
          [
             "assistant",
             "electrical",
             "labourers",
             "miscellaneous",
             "other",
             "telecommunications",
             "trades"
          ]
       ],
       [
          "Electrician (General)",
          [
             "electrician",
             "electricians",
             "electrotechnology",
             "general",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Electrician (Special Class)",
          [
             "class",
             "electrician",
             "electricians",
             "electrotechnology",
             "special",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Electronic Engineering Draftsperson",
          [
             "building",
             "draftsperson",
             "draftspersons",
             "electronic",
             "engineering",
             "ict",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Electronic Engineering Technician",
          [
             "building",
             "draftspersons",
             "electronic",
             "engineering",
             "ict",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Electronic Equipment Trades Worker",
          [
             "electronic",
             "electronics",
             "electrotechnology",
             "equipment",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Electronic Instrument Trades Worker (General)",
          [
             "electronic",
             "electronics",
             "electrotechnology",
             "general",
             "instrument",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Electronic Instrument Trades Worker (Special Class)",
          [
             "class",
             "electronic",
             "electronics",
             "electrotechnology",
             "instrument",
             "special",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Electronics Engineer",
          [
             "design",
             "electronics",
             "engineer",
             "engineering",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Electroplater",
          [
             "automotive",
             "casting",
             "electroplater",
             "engineering",
             "fabrication",
             "finishing",
             "forging",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Emergency Medicine Specialist",
          [
             "emergency",
             "health",
             "medical",
             "medicine",
             "other",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Emergency Service Worker",
          [
             "community",
             "defence",
             "emergency",
             "fighters",
             "fire",
             "force",
             "members",
             "personal",
             "police",
             "protective",
             "service",
             "workers"
          ]
       ],
       [
          "Endocrinologist",
          [
             "endocrinologist",
             "health",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Engineering Manager",
          [
             "construction",
             "distribution",
             "engineering",
             "manager",
             "managers",
             "production",
             "specialist"
          ]
       ],
       [
          "Engineering Patternmaker",
          [
             "automotive",
             "engineering",
             "mechanical",
             "patternmaker",
             "patternmakers",
             "technicians",
             "toolmakers",
             "trades",
             "workers"
          ]
       ],
       [
          "Engineering Production Worker",
          [
             "drivers",
             "engineering",
             "machine",
             "machinery",
             "operators",
             "plant",
             "production",
             "stationary",
             "worker"
          ]
       ],
       [
          "Engineering Professionals",
          [
             "design",
             "engineering",
             "other",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Engineering Technologist",
          [
             "design",
             "engineering",
             "other",
             "professionals",
             "science",
             "technologist",
             "transport"
          ]
       ],
       [
          "Engraver",
          [
             "automotive",
             "engineering",
             "engraver",
             "mechanical",
             "metal",
             "precision",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Enrolled Nurse",
          [
             "community",
             "enrolled",
             "health",
             "mothercraft",
             "nurse",
             "nurses",
             "personal",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Entertainer or Variety Artist",
          [
             "actors",
             "artist",
             "arts",
             "dancers",
             "entertainer",
             "entertainers",
             "media",
             "other",
             "professionals",
             "variety"
          ]
       ],
       [
          "Environmental Consultant",
          [
             "consultant",
             "design",
             "engineering",
             "environmental",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Environmental Engineer",
          [
             "design",
             "engineer",
             "engineering",
             "environmental",
             "other",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Environmental Health Officer",
          [
             "diagnostic",
             "environmental",
             "health",
             "occupational",
             "officer",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Environmental Manager",
          [
             "environmental",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "specialist"
          ]
       ],
       [
          "Environmental Research Scientist",
          [
             "design",
             "engineering",
             "environmental",
             "natural",
             "physical",
             "professionals",
             "research",
             "science",
             "scientist",
             "scientists",
             "transport"
          ]
       ],
       [
          "Environmental Scientists",
          [
             "design",
             "engineering",
             "environmental",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Equipment Hire Manager",
          [
             "equipment",
             "hire",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Excavator Operator",
          [
             "drivers",
             "earthmoving",
             "excavator",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Exhaust and Muffler Repairer",
          [
             "accessories",
             "exhaust",
             "fitters",
             "labourers",
             "miscellaneous",
             "motor",
             "muffler",
             "other",
             "parts",
             "repairer",
             "vehicle"
          ]
       ],
       [
          "External Auditor",
          [
             "accountants",
             "auditor",
             "auditors",
             "business",
             "company",
             "corporate",
             "external",
             "human",
             "marketing",
             "professionals",
             "resource",
             "secretaries",
             "treasurers"
          ]
       ],
       [
          "Fabric and Textile Factory Worker",
          [
             "fabric",
             "factory",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "textile",
             "workers"
          ]
       ],
       [
          "Facilities Administrator",
          [
             "administrative",
             "administrator",
             "clerical",
             "facilities",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Facilities Manager",
          [
             "facilities",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Factory Process Workers",
          [
             "factory",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "workers"
          ]
       ],
       [
          "Faculty Head",
          [
             "education",
             "faculty",
             "head",
             "health",
             "managers",
             "other",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Family Day Care Worker",
          [
             "aides",
             "care",
             "carers",
             "child",
             "community",
             "day",
             "family",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Family Support Worker",
          [
             "community",
             "family",
             "health",
             "personal",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Family and Marriage Counsellor",
          [
             "counsellor",
             "counsellors",
             "family",
             "legal",
             "marriage",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Farm, Forestry and Garden Workers",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "other",
             "workers"
          ]
       ],
       [
          "Farrier",
          [
             "automotive",
             "casting",
             "engineering",
             "fabrication",
             "farrier",
             "finishing",
             "forging",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Fashion Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "fashion",
             "industrial",
             "jewellery",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Fast Food Cook",
          [
             "assistants",
             "cook",
             "fast",
             "food",
             "labourers",
             "preparation"
          ]
       ],
       [
          "Fencer",
          [
             "construction",
             "fencer",
             "labourers",
             "mining"
          ]
       ],
       [
          "Fibrous Plasterer",
          [
             "construction",
             "fibrous",
             "glaziers",
             "plasterer",
             "plasterers",
             "technicians",
             "tilers",
             "trades",
             "workers"
          ]
       ],
       [
          "Filing or Registry Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "filing",
             "office",
             "registry",
             "support",
             "workers"
          ]
       ],
       [
          "Film and Video Editor",
          [
             "arts",
             "directors",
             "editor",
             "film",
             "media",
             "professionals",
             "radio",
             "stage",
             "television",
             "video"
          ]
       ],
       [
          "Film, Television, Radio and Stage Directors",
          [
             "arts",
             "directors",
             "film",
             "media",
             "professionals",
             "radio",
             "stage",
             "television"
          ]
       ],
       [
          "Finance Broker",
          [
             "advisers",
             "broker",
             "brokers",
             "business",
             "dealers",
             "finance",
             "financial",
             "human",
             "investment",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Finance Manager",
          [
             "administration",
             "business",
             "finance",
             "manager",
             "managers",
             "specialist"
          ]
       ],
       [
          "Financial Brokers",
          [
             "advisers",
             "brokers",
             "business",
             "dealers",
             "financial",
             "human",
             "investment",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Financial Dealers",
          [
             "advisers",
             "brokers",
             "business",
             "dealers",
             "financial",
             "human",
             "investment",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Financial Institution Branch Manager",
          [
             "branch",
             "financial",
             "hospitality",
             "institution",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Financial Investment Adviser",
          [
             "adviser",
             "advisers",
             "brokers",
             "business",
             "dealers",
             "financial",
             "human",
             "investment",
             "managers",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Financial Investment Manager",
          [
             "advisers",
             "brokers",
             "business",
             "dealers",
             "financial",
             "human",
             "investment",
             "manager",
             "managers",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Financial Market Dealer",
          [
             "advisers",
             "brokers",
             "business",
             "dealer",
             "dealers",
             "financial",
             "human",
             "investment",
             "market",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Fire Fighter",
          [
             "community",
             "defence",
             "emergency",
             "fighter",
             "fighters",
             "fire",
             "force",
             "members",
             "personal",
             "police",
             "protective",
             "service",
             "workers"
          ]
       ],
       [
          "Fire Protection Equipment Technician",
          [
             "equipment",
             "fire",
             "miscellaneous",
             "other",
             "protection",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "First Aid Trainer",
          [
             "aid",
             "community",
             "first",
             "other",
             "personal",
             "service",
             "sports",
             "trainer",
             "travel",
             "workers"
          ]
       ],
       [
          "Fisheries Officer",
          [
             "agricultural",
             "engineering",
             "fisheries",
             "ict",
             "inspectors",
             "medical",
             "officer",
             "primary",
             "products",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Fishing Guide",
          [
             "adventure",
             "community",
             "fishing",
             "fitness",
             "guide",
             "guides",
             "outdoor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Fishing Hand",
          [
             "deck",
             "fishing",
             "hand",
             "hands",
             "labourers",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Fitness Centre Manager",
          [
             "amusement",
             "centre",
             "fitness",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service",
             "sports"
          ]
       ],
       [
          "Fitness Instructor",
          [
             "community",
             "fitness",
             "instructor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Fitter (General)",
          [
             "automotive",
             "engineering",
             "fitter",
             "fitters",
             "general",
             "machinists",
             "mechanical",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Fitter and Turner",
          [
             "automotive",
             "engineering",
             "fitter",
             "fitters",
             "machinists",
             "mechanical",
             "metal",
             "technicians",
             "trades",
             "turner",
             "workers"
          ]
       ],
       [
          "Fitter-Welder",
          [
             "automotive",
             "engineering",
             "fitters",
             "fitterwelder",
             "machinists",
             "mechanical",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Fleet Manager",
          [
             "fleet",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service",
             "services",
             "transport"
          ]
       ],
       [
          "Flight Attendant",
          [
             "attendant",
             "attendants",
             "community",
             "flight",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Floor Finisher",
          [
             "construction",
             "finisher",
             "finishers",
             "floor",
             "painting",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Florist",
          [
             "animal",
             "florist",
             "horticultural",
             "skilled",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Flower Grower",
          [
             "crop",
             "farm",
             "farmers",
             "flower",
             "grower",
             "managers"
          ]
       ],
       [
          "Flying Instructor",
          [
             "air",
             "design",
             "engineering",
             "flying",
             "instructor",
             "marine",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Food Technologist",
          [
             "chemists",
             "design",
             "engineering",
             "food",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "technologist",
             "transport",
             "wine"
          ]
       ],
       [
          "Food Trades Assistants",
          [
             "assistants",
             "food",
             "labourers",
             "preparation",
             "trades"
          ]
       ],
       [
          "Food and Drink Factory Workers",
          [
             "drink",
             "factory",
             "food",
             "labourers",
             "process",
             "workers"
          ]
       ],
       [
          "Footballer",
          [
             "community",
             "fitness",
             "footballer",
             "personal",
             "service",
             "sports",
             "sportspersons",
             "workers"
          ]
       ],
       [
          "Footwear Factory Worker",
          [
             "factory",
             "footwear",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "workers"
          ]
       ],
       [
          "Footwear Production Machine Operator",
          [
             "drivers",
             "footwear",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "production",
             "stationary",
             "textile"
          ]
       ],
       [
          "Forester",
          [
             "agricultural",
             "design",
             "engineering",
             "forester",
             "forestry",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Forestry Worker",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "logging",
             "workers"
          ]
       ],
       [
          "Forklift Driver",
          [
             "driver",
             "drivers",
             "forklift",
             "machinery",
             "mobile",
             "operators",
             "plant"
          ]
       ],
       [
          "Freight Handler (Rail or Road)",
          [
             "fillers",
             "freight",
             "furniture",
             "handler",
             "handlers",
             "labourers",
             "other",
             "rail",
             "road",
             "shelf"
          ]
       ],
       [
          "Fruit and Vegetable Factory Worker",
          [
             "drink",
             "factory",
             "food",
             "fruit",
             "labourers",
             "process",
             "vegetable",
             "workers"
          ]
       ],
       [
          "Fruit and Vegetable Packer",
          [
             "assemblers",
             "factory",
             "fruit",
             "labourers",
             "packer",
             "packers",
             "process",
             "product",
             "vegetable",
             "workers"
          ]
       ],
       [
          "Fruit or Nut Farm Worker",
          [
             "crop",
             "farm",
             "forestry",
             "fruit",
             "garden",
             "labourers",
             "nut",
             "workers"
          ]
       ],
       [
          "Fruit or Nut Grower",
          [
             "crop",
             "farm",
             "farmers",
             "fruit",
             "grower",
             "managers",
             "nut"
          ]
       ],
       [
          "Fruit or Nut Picker",
          [
             "crop",
             "farm",
             "forestry",
             "fruit",
             "garden",
             "labourers",
             "nut",
             "picker",
             "workers"
          ]
       ],
       [
          "Funeral Director",
          [
             "community",
             "director",
             "funeral",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Funeral Workers",
          [
             "community",
             "funeral",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Furniture Finisher",
          [
             "finisher",
             "furniture",
             "machinists",
             "other",
             "technicians",
             "trades",
             "wood",
             "workers"
          ]
       ],
       [
          "Furniture Removalist",
          [
             "drivers",
             "furniture",
             "machinery",
             "operators",
             "rail",
             "removalist",
             "road",
             "truck"
          ]
       ],
       [
          "Futures Trader",
          [
             "advisers",
             "brokers",
             "business",
             "dealers",
             "financial",
             "futures",
             "human",
             "investment",
             "marketing",
             "professionals",
             "resource",
             "trader"
          ]
       ],
       [
          "Gallery or Museum Curator",
          [
             "archivists",
             "business",
             "curator",
             "curators",
             "gallery",
             "human",
             "information",
             "managers",
             "marketing",
             "museum",
             "organisation",
             "professionals",
             "records",
             "resource"
          ]
       ],
       [
          "Gallery or Museum Guide",
          [
             "community",
             "gallery",
             "guide",
             "guides",
             "museum",
             "personal",
             "service",
             "sports",
             "tour",
             "travel",
             "workers"
          ]
       ],
       [
          "Gallery or Museum Technician",
          [
             "gallery",
             "library",
             "miscellaneous",
             "museum",
             "other",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Gaming Worker",
          [
             "community",
             "gaming",
             "hospitality",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Garden Labourer",
          [
             "farm",
             "forestry",
             "garden",
             "labourer",
             "labourers",
             "nursery",
             "workers"
          ]
       ],
       [
          "Gardener (General)",
          [
             "animal",
             "gardener",
             "gardeners",
             "general",
             "horticultural",
             "skilled",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Gas or Petroleum Operator",
          [
             "chemical",
             "gas",
             "generation",
             "miscellaneous",
             "operator",
             "operators",
             "other",
             "petroleum",
             "plant",
             "power",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Gasfitter",
          [
             "construction",
             "gasfitter",
             "plumbers",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Gastroenterologist",
          [
             "gastroenterologist",
             "health",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "General Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "general",
             "workers"
          ]
       ],
       [
          "General Medical Practitioner",
          [
             "general",
             "generalist",
             "health",
             "medical",
             "practitioner",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Geologist",
          [
             "design",
             "engineering",
             "geologist",
             "geologists",
             "geophysicists",
             "natural",
             "physical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Geophysicist",
          [
             "design",
             "engineering",
             "geologists",
             "geophysicist",
             "geophysicists",
             "natural",
             "physical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Geotechnical Engineer",
          [
             "civil",
             "design",
             "engineer",
             "engineering",
             "geotechnical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Glass Processing Worker",
          [
             "factory",
             "glass",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "processing",
             "workers"
          ]
       ],
       [
          "Glass Production Machine Operator",
          [
             "clay",
             "concrete",
             "drivers",
             "glass",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "processing",
             "production",
             "stationary",
             "stone"
          ]
       ],
       [
          "Glazier",
          [
             "construction",
             "glazier",
             "glaziers",
             "plasterers",
             "technicians",
             "tilers",
             "trades",
             "workers"
          ]
       ],
       [
          "Goat Farmer",
          [
             "farm",
             "farmer",
             "farmers",
             "goat",
             "livestock",
             "managers"
          ]
       ],
       [
          "Golfer",
          [
             "community",
             "fitness",
             "golfer",
             "personal",
             "service",
             "sports",
             "sportspersons",
             "workers"
          ]
       ],
       [
          "Grader Operator",
          [
             "drivers",
             "earthmoving",
             "grader",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Grain Mill Worker",
          [
             "drink",
             "factory",
             "food",
             "grain",
             "labourers",
             "mill",
             "process",
             "workers"
          ]
       ],
       [
          "Grain, Oilseed or Pasture Farm Worker",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "grain",
             "labourers",
             "oilseed",
             "pasture",
             "workers"
          ]
       ],
       [
          "Grain, Oilseed or Pasture Grower",
          [
             "crop",
             "farm",
             "farmers",
             "grain",
             "grower",
             "managers",
             "oilseed",
             "pasture"
          ]
       ],
       [
          "Grape Grower",
          [
             "crop",
             "farm",
             "farmers",
             "grape",
             "grower",
             "managers"
          ]
       ],
       [
          "Graphic Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "graphic",
             "illustrators",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport",
             "web"
          ]
       ],
       [
          "Graphic Pre-press Trades Worker",
          [
             "graphic",
             "other",
             "prepress",
             "printing",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Greenkeeper",
          [
             "animal",
             "greenkeeper",
             "horticultural",
             "skilled",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Gunsmith",
          [
             "automotive",
             "engineering",
             "gunsmith",
             "mechanical",
             "metal",
             "precision",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Gymnastics Coach or Instructor",
          [
             "coach",
             "coaches",
             "community",
             "fitness",
             "gymnastics",
             "instructor",
             "instructors",
             "officials",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Hair or Beauty Salon Assistant",
          [
             "assistant",
             "beauty",
             "community",
             "hair",
             "other",
             "personal",
             "salon",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Hair or Beauty Salon Manager",
          [
             "beauty",
             "hair",
             "hospitality",
             "manager",
             "managers",
             "retail",
             "salon",
             "service"
          ]
       ],
       [
          "Hairdresser",
          [
             "hairdresser",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Handyperson",
          [
             "handyperson",
             "labourers",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Hardware Technician",
          [
             "engineering",
             "hardware",
             "ict",
             "science",
             "support",
             "technician",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Health Diagnostic and Promotion Professionals",
          [
             "diagnostic",
             "health",
             "other",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Health Information Manager",
          [
             "archivists",
             "business",
             "curators",
             "health",
             "human",
             "information",
             "manager",
             "managers",
             "marketing",
             "organisation",
             "professionals",
             "records",
             "resource"
          ]
       ],
       [
          "Health Practice Manager",
          [
             "administrative",
             "administrators",
             "clerical",
             "health",
             "manager",
             "managers",
             "office",
             "practice",
             "program",
             "workers"
          ]
       ],
       [
          "Health Promotion Officer",
          [
             "diagnostic",
             "health",
             "officer",
             "other",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Health and Welfare Services Managers",
          [
             "education",
             "health",
             "managers",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Helicopter Pilot",
          [
             "air",
             "design",
             "engineering",
             "helicopter",
             "marine",
             "pilot",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Hide and Skin Processing Machine Operator",
          [
             "drivers",
             "footwear",
             "hide",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "processing",
             "production",
             "skin",
             "stationary",
             "textile"
          ]
       ],
       [
          "Hide and Skin Processing Worker",
          [
             "factory",
             "hide",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "processing",
             "skin",
             "workers"
          ]
       ],
       [
          "Historian",
          [
             "historian",
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Home Improvement Installer",
          [
             "construction",
             "home",
             "improvement",
             "installer",
             "installers",
             "insulation",
             "labourers",
             "mining"
          ]
       ],
       [
          "Homoeopath",
          [
             "complementary",
             "health",
             "homoeopath",
             "professionals",
             "therapists",
             "therapy"
          ]
       ],
       [
          "Horse Breeder",
          [
             "breeder",
             "farm",
             "farmers",
             "horse",
             "livestock",
             "managers"
          ]
       ],
       [
          "Horse Riding Coach or Instructor",
          [
             "coach",
             "coaches",
             "community",
             "fitness",
             "horse",
             "instructor",
             "instructors",
             "officials",
             "personal",
             "riding",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Horse Trainer",
          [
             "animal",
             "attendants",
             "horse",
             "horticultural",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainer",
             "trainers",
             "workers"
          ]
       ],
       [
          "Horticultural Nursery Assistant",
          [
             "assistant",
             "farm",
             "forestry",
             "garden",
             "horticultural",
             "labourers",
             "nursery",
             "workers"
          ]
       ],
       [
          "Hospital Orderly",
          [
             "aides",
             "assistants",
             "care",
             "carers",
             "community",
             "hospital",
             "nursing",
             "orderly",
             "personal",
             "service",
             "support",
             "workers"
          ]
       ],
       [
          "Hospital Pharmacist",
          [
             "diagnostic",
             "health",
             "hospital",
             "pharmacist",
             "pharmacists",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Hospitality Workers",
          [
             "community",
             "hospitality",
             "other",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Hospitality, Retail and Service Managers",
          [
             "hospitality",
             "managers",
             "miscellaneous",
             "other",
             "retail",
             "service"
          ]
       ],
       [
          "Hostel Parent",
          [
             "aides",
             "assistants",
             "care",
             "carers",
             "community",
             "hostel",
             "parent",
             "personal",
             "service",
             "special",
             "workers"
          ]
       ],
       [
          "Hotel Service Manager",
          [
             "community",
             "hospitality",
             "hotel",
             "manager",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Hotel or Motel Manager",
          [
             "accommodation",
             "hospitality",
             "hotel",
             "manager",
             "managers",
             "motel",
             "retail",
             "service"
          ]
       ],
       [
          "Hotel or Motel Receptionist",
          [
             "administrative",
             "clerical",
             "clerks",
             "hotel",
             "inquiry",
             "motel",
             "receptionist",
             "receptionists",
             "workers"
          ]
       ],
       [
          "Human Resource Adviser",
          [
             "adviser",
             "business",
             "human",
             "marketing",
             "professionals",
             "resource",
             "training"
          ]
       ],
       [
          "Human Resource Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "human",
             "miscellaneous",
             "other",
             "resource",
             "workers"
          ]
       ],
       [
          "Human Resource Manager",
          [
             "administration",
             "business",
             "human",
             "manager",
             "managers",
             "resource",
             "specialist"
          ]
       ],
       [
          "Hunter-Trapper",
          [
             "farm",
             "forestry",
             "garden",
             "huntertrapper",
             "labourers",
             "other",
             "workers"
          ]
       ],
       [
          "Hunting Guide",
          [
             "adventure",
             "community",
             "fitness",
             "guide",
             "guides",
             "hunting",
             "outdoor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "ICT Account Manager",
          [
             "account",
             "business",
             "human",
             "ict",
             "manager",
             "marketing",
             "professionals",
             "public",
             "relations",
             "resource",
             "sales"
          ]
       ],
       [
          "ICT Business Analyst",
          [
             "analyst",
             "analysts",
             "business",
             "ict",
             "professionals",
             "programmers",
             "systems"
          ]
       ],
       [
          "ICT Business Development Manager",
          [
             "business",
             "development",
             "human",
             "ict",
             "manager",
             "marketing",
             "professionals",
             "public",
             "relations",
             "resource",
             "sales"
          ]
       ],
       [
          "ICT Customer Support Officer",
          [
             "customer",
             "engineering",
             "ict",
             "officer",
             "science",
             "support",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "ICT Managers",
          [
             "ict",
             "managers",
             "specialist"
          ]
       ],
       [
          "ICT Project Manager",
          [
             "ict",
             "manager",
             "managers",
             "project",
             "specialist"
          ]
       ],
       [
          "ICT Quality Assurance Engineer",
          [
             "assurance",
             "engineer",
             "engineers",
             "ict",
             "network",
             "professionals",
             "quality",
             "support",
             "test"
          ]
       ],
       [
          "ICT Sales Assistant",
          [
             "assistant",
             "assistants",
             "ict",
             "sales",
             "salespersons",
             "workers"
          ]
       ],
       [
          "ICT Sales Representative",
          [
             "business",
             "human",
             "ict",
             "marketing",
             "professionals",
             "public",
             "relations",
             "representative",
             "resource",
             "sales"
          ]
       ],
       [
          "ICT Security Specialist",
          [
             "administrators",
             "database",
             "ict",
             "professionals",
             "security",
             "specialist",
             "specialists",
             "systems"
          ]
       ],
       [
          "ICT Support Engineer",
          [
             "engineer",
             "engineers",
             "ict",
             "network",
             "professionals",
             "support",
             "test"
          ]
       ],
       [
          "ICT Support Technicians",
          [
             "engineering",
             "ict",
             "science",
             "support",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "ICT Support and Test Engineers",
          [
             "engineers",
             "ict",
             "network",
             "professionals",
             "support",
             "test"
          ]
       ],
       [
          "ICT Systems Test Engineer",
          [
             "engineer",
             "engineers",
             "ict",
             "network",
             "professionals",
             "support",
             "systems",
             "test"
          ]
       ],
       [
          "ICT Trainer",
          [
             "business",
             "human",
             "ict",
             "marketing",
             "professionals",
             "resource",
             "trainer",
             "training"
          ]
       ],
       [
          "Illustrator",
          [
             "architects",
             "design",
             "designers",
             "engineering",
             "graphic",
             "illustrator",
             "illustrators",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport",
             "web"
          ]
       ],
       [
          "Immigration Officer",
          [
             "administrative",
             "clerical",
             "immigration",
             "inspectors",
             "miscellaneous",
             "officer",
             "officers",
             "other",
             "regulatory",
             "workers"
          ]
       ],
       [
          "Import-Export Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "despatch",
             "importexport",
             "logistics",
             "other",
             "transport",
             "workers"
          ]
       ],
       [
          "Importer or Exporter",
          [
             "construction",
             "distribution",
             "exporter",
             "exporters",
             "importer",
             "importers",
             "managers",
             "production",
             "specialist",
             "wholesalers"
          ]
       ],
       [
          "Industrial Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "fashion",
             "industrial",
             "jewellery",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Industrial Engineer",
          [
             "design",
             "engineer",
             "engineering",
             "engineers",
             "industrial",
             "mechanical",
             "production",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Industrial Pharmacist",
          [
             "diagnostic",
             "health",
             "industrial",
             "pharmacist",
             "pharmacists",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Industrial Spraypainter",
          [
             "drivers",
             "industrial",
             "machine",
             "machinery",
             "operators",
             "plant",
             "spraypainter",
             "stationary"
          ]
       ],
       [
          "Information and Organisation Professionals",
          [
             "business",
             "human",
             "information",
             "marketing",
             "organisation",
             "other",
             "professionals",
             "resource"
          ]
       ],
       [
          "Inquiry Clerk",
          [
             "administrative",
             "call",
             "centre",
             "clerical",
             "clerk",
             "clerks",
             "contact",
             "information",
             "inquiry",
             "receptionists",
             "workers"
          ]
       ],
       [
          "Inspectors and Regulatory Officers",
          [
             "administrative",
             "clerical",
             "inspectors",
             "miscellaneous",
             "officers",
             "other",
             "regulatory",
             "workers"
          ]
       ],
       [
          "Insurance Agent",
          [
             "agent",
             "agents",
             "insurance",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Insurance Broker",
          [
             "advisers",
             "broker",
             "brokers",
             "business",
             "dealers",
             "financial",
             "human",
             "insurance",
             "investment",
             "marketing",
             "professionals",
             "resource"
          ]
       ],
       [
          "Insurance Consultant",
          [
             "administrative",
             "clerical",
             "clerks",
             "consultant",
             "financial",
             "insurance",
             "market",
             "money",
             "numerical",
             "statistical",
             "workers"
          ]
       ],
       [
          "Insurance Investigator",
          [
             "adjusters",
             "administrative",
             "clerical",
             "insurance",
             "investigator",
             "investigators",
             "loss",
             "miscellaneous",
             "other",
             "risk",
             "surveyors",
             "workers"
          ]
       ],
       [
          "Insurance Loss Adjuster",
          [
             "adjuster",
             "adjusters",
             "administrative",
             "clerical",
             "insurance",
             "investigators",
             "loss",
             "miscellaneous",
             "other",
             "risk",
             "surveyors",
             "workers"
          ]
       ],
       [
          "Insurance Risk Surveyor",
          [
             "adjusters",
             "administrative",
             "clerical",
             "insurance",
             "investigators",
             "loss",
             "miscellaneous",
             "other",
             "risk",
             "surveyor",
             "surveyors",
             "workers"
          ]
       ],
       [
          "Integration Aide",
          [
             "aide",
             "aides",
             "carers",
             "community",
             "education",
             "integration",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Intelligence Officer",
          [
             "analysts",
             "business",
             "human",
             "information",
             "intelligence",
             "marketing",
             "officer",
             "organisation",
             "policy",
             "professionals",
             "resource"
          ]
       ],
       [
          "Intensive Care Ambulance Paramedic",
          [
             "ambulance",
             "care",
             "community",
             "health",
             "intensive",
             "officers",
             "paramedic",
             "paramedics",
             "personal",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Intensive Care Specialist",
          [
             "care",
             "health",
             "intensive",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Interior Decorator",
          [
             "decorator",
             "interior",
             "miscellaneous",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Interior Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "interior",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Internal Auditor",
          [
             "accountants",
             "auditor",
             "auditors",
             "business",
             "company",
             "corporate",
             "human",
             "internal",
             "marketing",
             "professionals",
             "resource",
             "secretaries",
             "treasurers"
          ]
       ],
       [
          "Interpreter",
          [
             "interpreter",
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Ironer or Presser",
          [
             "cleaners",
             "ironer",
             "labourers",
             "laundry",
             "presser",
             "workers"
          ]
       ],
       [
          "Jeweller",
          [
             "jeweller",
             "miscellaneous",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Jewellery Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "fashion",
             "industrial",
             "jewellery",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Jockey",
          [
             "community",
             "fitness",
             "jockey",
             "personal",
             "service",
             "sports",
             "sportspersons",
             "workers"
          ]
       ],
       [
          "Joiner",
          [
             "bricklayers",
             "carpenters",
             "construction",
             "joiner",
             "joiners",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Journalists and Other Writers",
          [
             "arts",
             "journalists",
             "media",
             "other",
             "professionals",
             "writers"
          ]
       ],
       [
          "Judge",
          [
             "judge",
             "judicial",
             "legal",
             "other",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Judicial and Other Legal Professionals",
          [
             "judicial",
             "legal",
             "other",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Kitchenhand",
          [
             "assistants",
             "food",
             "kitchenhand",
             "labourers",
             "preparation"
          ]
       ],
       [
          "Knitting Machine Operator",
          [
             "drivers",
             "footwear",
             "knitting",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "production",
             "stationary",
             "textile"
          ]
       ],
       [
          "Laboratory Manager",
          [
             "laboratory",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "specialist"
          ]
       ],
       [
          "Labourers",
          [
             "labourers",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Lagger",
          [
             "construction",
             "labourers",
             "lagger",
             "mining",
             "other"
          ]
       ],
       [
          "Land Economist",
          [
             "business",
             "economist",
             "economists",
             "human",
             "information",
             "land",
             "marketing",
             "organisation",
             "professionals",
             "resource",
             "valuers"
          ]
       ],
       [
          "Landscape Architect",
          [
             "architect",
             "architects",
             "design",
             "designers",
             "engineering",
             "landscape",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Landscape Gardener",
          [
             "animal",
             "gardener",
             "gardeners",
             "horticultural",
             "landscape",
             "skilled",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Laundry Worker (General)",
          [
             "cleaners",
             "general",
             "labourers",
             "laundry",
             "workers"
          ]
       ],
       [
          "Law Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "court",
             "law",
             "legal",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Leaflet or Newspaper Deliverer",
          [
             "deliverer",
             "labourers",
             "leaflet",
             "miscellaneous",
             "newspaper",
             "other"
          ]
       ],
       [
          "Leather Goods Maker",
          [
             "canvas",
             "clothing",
             "footwear",
             "goods",
             "leather",
             "maker",
             "makers",
             "other",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Legal Executive",
          [
             "administrative",
             "clerical",
             "conveyancers",
             "executive",
             "executives",
             "legal",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Legal Secretary",
          [
             "administrative",
             "assistants",
             "clerical",
             "legal",
             "personal",
             "secretaries",
             "secretary",
             "workers"
          ]
       ],
       [
          "Legislators",
          [
             "chief",
             "executives",
             "general",
             "legislators",
             "managers"
          ]
       ],
       [
          "Liaison Officer",
          [
             "business",
             "human",
             "information",
             "liaison",
             "marketing",
             "officer",
             "organisation",
             "other",
             "professionals",
             "resource"
          ]
       ],
       [
          "Librarian",
          [
             "business",
             "human",
             "information",
             "librarian",
             "marketing",
             "organisation",
             "professionals",
             "resource"
          ]
       ],
       [
          "Library Assistant",
          [
             "administrative",
             "assistant",
             "clerical",
             "library",
             "miscellaneous",
             "other",
             "workers"
          ]
       ],
       [
          "Library Technician",
          [
             "gallery",
             "library",
             "miscellaneous",
             "museum",
             "other",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Licensed Club Manager",
          [
             "accommodation",
             "club",
             "hospitality",
             "licensed",
             "manager",
             "managers",
             "retail",
             "service"
          ]
       ],
       [
          "Life Science Technician",
          [
             "agricultural",
             "engineering",
             "ict",
             "life",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Life Scientist (General)",
          [
             "design",
             "engineering",
             "general",
             "life",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientist",
             "scientists",
             "transport"
          ]
       ],
       [
          "Life Scientists",
          [
             "design",
             "engineering",
             "life",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Lifeguard",
          [
             "community",
             "fitness",
             "lifeguard",
             "personal",
             "service",
             "sports",
             "sportspersons",
             "workers"
          ]
       ],
       [
          "Lift Mechanic",
          [
             "electricians",
             "electrotechnology",
             "lift",
             "mechanic",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Light Technician",
          [
             "arts",
             "light",
             "miscellaneous",
             "other",
             "performing",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Linemarker",
          [
             "drivers",
             "linemarker",
             "machinery",
             "mobile",
             "operators",
             "other",
             "plant"
          ]
       ],
       [
          "Livestock Farm Workers",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "workers"
          ]
       ],
       [
          "Livestock Farmers",
          [
             "farm",
             "farmers",
             "livestock",
             "managers"
          ]
       ],
       [
          "Loader Operator",
          [
             "drivers",
             "earthmoving",
             "loader",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Local Government Legislator",
          [
             "chief",
             "executives",
             "general",
             "government",
             "legislator",
             "legislators",
             "local",
             "managers"
          ]
       ],
       [
          "Locksmith",
          [
             "automotive",
             "engineering",
             "locksmith",
             "mechanical",
             "metal",
             "precision",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Logging Assistant",
          [
             "assistant",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "logging",
             "workers"
          ]
       ],
       [
          "Logging Plant Operator",
          [
             "agricultural",
             "drivers",
             "forestry",
             "horticultural",
             "logging",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "plant"
          ]
       ],
       [
          "Machine Operators",
          [
             "drivers",
             "machine",
             "machinery",
             "operators",
             "other",
             "plant",
             "stationary"
          ]
       ],
       [
          "Machine Shorthand Reporter",
          [
             "administrative",
             "clerical",
             "general",
             "keyboard",
             "machine",
             "operators",
             "reporter",
             "shorthand",
             "workers"
          ]
       ],
       [
          "Magistrate",
          [
             "judicial",
             "legal",
             "magistrate",
             "other",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Mail Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "mail",
             "office",
             "sorters",
             "support",
             "workers"
          ]
       ],
       [
          "Maintenance Planner",
          [
             "building",
             "engineering",
             "ict",
             "maintenance",
             "other",
             "planner",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Make Up Artist",
          [
             "artist",
             "arts",
             "make",
             "miscellaneous",
             "other",
             "performing",
             "technicians",
             "trades",
             "up",
             "workers"
          ]
       ],
       [
          "Management Accountant",
          [
             "accountant",
             "accountants",
             "auditors",
             "business",
             "company",
             "human",
             "management",
             "marketing",
             "professionals",
             "resource",
             "secretaries"
          ]
       ],
       [
          "Management Consultant",
          [
             "analysts",
             "business",
             "consultant",
             "human",
             "information",
             "management",
             "marketing",
             "organisation",
             "professionals",
             "resource"
          ]
       ],
       [
          "Manufacturer",
          [
             "construction",
             "distribution",
             "managers",
             "manufacturer",
             "production",
             "specialist"
          ]
       ],
       [
          "Marine Biologist",
          [
             "biologist",
             "design",
             "engineering",
             "life",
             "marine",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Marine Transport Professionals",
          [
             "air",
             "design",
             "engineering",
             "marine",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Market Research Analyst",
          [
             "advertising",
             "analyst",
             "business",
             "human",
             "market",
             "marketing",
             "professionals",
             "public",
             "relations",
             "research",
             "resource",
             "sales"
          ]
       ],
       [
          "Marketing Specialist",
          [
             "advertising",
             "business",
             "human",
             "marketing",
             "professionals",
             "public",
             "relations",
             "resource",
             "sales",
             "specialist"
          ]
       ],
       [
          "Massage Therapist",
          [
             "community",
             "health",
             "massage",
             "personal",
             "service",
             "support",
             "therapist",
             "welfare",
             "workers"
          ]
       ],
       [
          "Master Fisher",
          [
             "air",
             "design",
             "engineering",
             "fisher",
             "marine",
             "master",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Materials Engineer",
          [
             "chemical",
             "design",
             "engineer",
             "engineering",
             "engineers",
             "materials",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Materials Recycler",
          [
             "assistants",
             "materials",
             "other",
             "recycler",
             "sales",
             "salespersons",
             "workers"
          ]
       ],
       [
          "Mathematician",
          [
             "actuaries",
             "business",
             "human",
             "information",
             "marketing",
             "mathematician",
             "mathematicians",
             "organisation",
             "professionals",
             "resource",
             "statisticians"
          ]
       ],
       [
          "Meat Boner and Slicer",
          [
             "boner",
             "boners",
             "factory",
             "food",
             "labourers",
             "meat",
             "process",
             "slaughterers",
             "slicer",
             "slicers",
             "workers"
          ]
       ],
       [
          "Meat Inspector",
          [
             "agricultural",
             "engineering",
             "ict",
             "inspector",
             "inspectors",
             "meat",
             "medical",
             "primary",
             "products",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Meat Packer",
          [
             "assemblers",
             "factory",
             "labourers",
             "meat",
             "packer",
             "packers",
             "process",
             "product",
             "workers"
          ]
       ],
       [
          "Meat Process Worker",
          [
             "factory",
             "food",
             "labourers",
             "meat",
             "poultry",
             "process",
             "seafood",
             "workers"
          ]
       ],
       [
          "Mechanic's Assistant",
          [
             "assistant",
             "labourers",
             "mechanics",
             "miscellaneous",
             "other"
          ]
       ],
       [
          "Mechanical Engineer",
          [
             "design",
             "engineer",
             "engineering",
             "engineers",
             "industrial",
             "mechanical",
             "production",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Mechanical Engineering Draftsperson",
          [
             "building",
             "draftsperson",
             "draftspersons",
             "engineering",
             "ict",
             "mechanical",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Mechanical Engineering Technician",
          [
             "building",
             "draftspersons",
             "engineering",
             "ict",
             "mechanical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Media Producer (excluding Video)",
          [
             "artistic",
             "arts",
             "directors",
             "excluding",
             "media",
             "presenters",
             "producer",
             "producers",
             "professionals",
             "video"
          ]
       ],
       [
          "Medical Administrator",
          [
             "administrator",
             "education",
             "health",
             "managers",
             "medical",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Medical Diagnostic Radiographer",
          [
             "diagnostic",
             "health",
             "imaging",
             "medical",
             "professionals",
             "promotion",
             "radiographer"
          ]
       ],
       [
          "Medical Laboratory Scientist",
          [
             "design",
             "engineering",
             "laboratory",
             "medical",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientist",
             "transport"
          ]
       ],
       [
          "Medical Laboratory Technician",
          [
             "agricultural",
             "engineering",
             "ict",
             "laboratory",
             "medical",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Medical Oncologist",
          [
             "health",
             "medical",
             "oncologist",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Medical Practitioners",
          [
             "health",
             "medical",
             "other",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Medical Radiation Therapist",
          [
             "diagnostic",
             "health",
             "imaging",
             "medical",
             "professionals",
             "promotion",
             "radiation",
             "therapist"
          ]
       ],
       [
          "Medical Receptionist",
          [
             "administrative",
             "clerical",
             "clerks",
             "inquiry",
             "medical",
             "receptionist",
             "receptionists",
             "workers"
          ]
       ],
       [
          "Medical Technicians",
          [
             "agricultural",
             "engineering",
             "ict",
             "medical",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Member of Parliament",
          [
             "chief",
             "executives",
             "general",
             "legislators",
             "managers",
             "member",
             "of",
             "parliament"
          ]
       ],
       [
          "Metal Casting Trades Worker",
          [
             "automotive",
             "casting",
             "engineering",
             "fabrication",
             "finishing",
             "forging",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Metal Engineering Process Worker",
          [
             "engineering",
             "factory",
             "labourers",
             "metal",
             "miscellaneous",
             "process",
             "workers"
          ]
       ],
       [
          "Metal Fabricator",
          [
             "automotive",
             "engineering",
             "fabrication",
             "fabricator",
             "metal",
             "steel",
             "structural",
             "technicians",
             "trades",
             "welding",
             "workers"
          ]
       ],
       [
          "Metal Fitters and Machinists",
          [
             "automotive",
             "engineering",
             "fitters",
             "machinists",
             "mechanical",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Metal Machinist (First Class)",
          [
             "automotive",
             "class",
             "engineering",
             "first",
             "fitters",
             "machinist",
             "machinists",
             "mechanical",
             "metal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Metal Polisher",
          [
             "automotive",
             "casting",
             "engineering",
             "fabrication",
             "finishing",
             "forging",
             "metal",
             "polisher",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Metallurgical or Materials Technician",
          [
             "building",
             "engineering",
             "ict",
             "materials",
             "metallurgical",
             "other",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Metallurgist",
          [
             "design",
             "engineering",
             "metallurgist",
             "natural",
             "other",
             "physical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Meteorologist",
          [
             "design",
             "engineering",
             "meteorologist",
             "natural",
             "other",
             "physical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Meter Reader",
          [
             "administrative",
             "clerical",
             "meter",
             "office",
             "other",
             "reader",
             "support",
             "workers"
          ]
       ],
       [
          "Microbiologist",
          [
             "design",
             "engineering",
             "life",
             "microbiologist",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Middle School Teacher",
          [
             "education",
             "middle",
             "professionals",
             "school",
             "teachers"
          ]
       ],
       [
          "Midwife",
          [
             "health",
             "midwife",
             "midwifery",
             "nursing",
             "professionals"
          ]
       ],
       [
          "Migration Agent",
          [
             "agent",
             "business",
             "human",
             "information",
             "marketing",
             "migration",
             "organisation",
             "other",
             "professionals",
             "resource"
          ]
       ],
       [
          "Mine Deputy",
          [
             "building",
             "deputy",
             "engineering",
             "ict",
             "mine",
             "other",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Miner",
          [
             "drillers",
             "drivers",
             "firers",
             "machine",
             "machinery",
             "miner",
             "miners",
             "operators",
             "plant",
             "shot",
             "stationary"
          ]
       ],
       [
          "Mining Engineer (excluding Petroleum)",
          [
             "design",
             "engineer",
             "engineering",
             "engineers",
             "excluding",
             "mining",
             "petroleum",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Mining Support Worker",
          [
             "construction",
             "labourers",
             "mining",
             "other",
             "support",
             "worker"
          ]
       ],
       [
          "Minister of Religion",
          [
             "legal",
             "minister",
             "of",
             "professionals",
             "religion",
             "social",
             "welfare"
          ]
       ],
       [
          "Mixed Crop Farmer",
          [
             "crop",
             "farm",
             "farmer",
             "farmers",
             "managers",
             "mixed"
          ]
       ],
       [
          "Mixed Crop and Livestock Farm Worker",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "mixed",
             "workers"
          ]
       ],
       [
          "Mixed Crop and Livestock Farmer",
          [
             "crop",
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers",
             "mixed"
          ]
       ],
       [
          "Mixed Livestock Farm Worker",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "mixed",
             "workers"
          ]
       ],
       [
          "Mixed Livestock Farmer",
          [
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers",
             "mixed"
          ]
       ],
       [
          "Mobile Plant Operators",
          [
             "drivers",
             "machinery",
             "mobile",
             "operators",
             "other",
             "plant"
          ]
       ],
       [
          "Model",
          [
             "demonstrators",
             "miscellaneous",
             "model",
             "models",
             "sales",
             "support",
             "workers"
          ]
       ],
       [
          "Money Market Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "financial",
             "insurance",
             "market",
             "money",
             "numerical",
             "statistical",
             "workers"
          ]
       ],
       [
          "Mothercraft Nurse",
          [
             "community",
             "enrolled",
             "health",
             "mothercraft",
             "nurse",
             "nurses",
             "personal",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Motion Picture Projectionist",
          [
             "drivers",
             "machine",
             "machinery",
             "motion",
             "operators",
             "other",
             "picture",
             "plant",
             "projectionist",
             "stationary"
          ]
       ],
       [
          "Motor Mechanic (General)",
          [
             "automotive",
             "electricians",
             "engineering",
             "general",
             "mechanic",
             "mechanics",
             "motor",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Motor Vehicle Licence Examiner",
          [
             "administrative",
             "clerical",
             "examiner",
             "inspectors",
             "licence",
             "miscellaneous",
             "motor",
             "officers",
             "other",
             "regulatory",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Motor Vehicle Parts Interpreter",
          [
             "assistants",
             "interpreter",
             "motor",
             "parts",
             "sales",
             "salespersons",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Motor Vehicle Parts and Accessories Fitter (General)",
          [
             "accessories",
             "fitter",
             "fitters",
             "general",
             "labourers",
             "miscellaneous",
             "motor",
             "other",
             "parts",
             "vehicle"
          ]
       ],
       [
          "Motor Vehicle or Caravan Salesperson",
          [
             "assistants",
             "caravan",
             "motor",
             "parts",
             "sales",
             "salesperson",
             "salespersons",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Motorcycle Mechanic",
          [
             "automotive",
             "electricians",
             "engineering",
             "mechanic",
             "mechanics",
             "motor",
             "motorcycle",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Mountain or Glacier Guide",
          [
             "adventure",
             "community",
             "fitness",
             "glacier",
             "guide",
             "guides",
             "mountain",
             "outdoor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Multimedia Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "graphic",
             "illustrators",
             "multimedia",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport",
             "web"
          ]
       ],
       [
          "Multimedia Specialist",
          [
             "analysts",
             "business",
             "developers",
             "ict",
             "multimedia",
             "professionals",
             "programmers",
             "specialist",
             "specialists",
             "systems",
             "web"
          ]
       ],
       [
          "Mushroom Picker",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "mushroom",
             "picker",
             "workers"
          ]
       ],
       [
          "Music Director",
          [
             "arts",
             "director",
             "media",
             "music",
             "professionals"
          ]
       ],
       [
          "Music Professionals",
          [
             "arts",
             "media",
             "music",
             "professionals"
          ]
       ],
       [
          "Music Teacher (Private Tuition)",
          [
             "education",
             "miscellaneous",
             "music",
             "private",
             "professionals",
             "teacher",
             "teachers",
             "tuition",
             "tutors"
          ]
       ],
       [
          "Musical Instrument Maker or Repairer",
          [
             "arts",
             "instrument",
             "maker",
             "miscellaneous",
             "musical",
             "other",
             "performing",
             "repairer",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Musician (Instrumental)",
          [
             "arts",
             "instrumental",
             "media",
             "music",
             "musician",
             "professionals"
          ]
       ],
       [
          "Nanny",
          [
             "aides",
             "carers",
             "child",
             "community",
             "nanny",
             "personal",
             "service",
             "workers"
          ]
       ],
       [
          "Natural Remedy Consultant",
          [
             "care",
             "community",
             "consultant",
             "consultants",
             "natural",
             "personal",
             "remedy",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Natural and Physical Science Professionals",
          [
             "design",
             "engineering",
             "natural",
             "other",
             "physical",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Naturopath",
          [
             "complementary",
             "health",
             "naturopath",
             "professionals",
             "therapists",
             "therapy"
          ]
       ],
       [
          "Naval Architect",
          [
             "architect",
             "design",
             "engineering",
             "naval",
             "other",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Network Administrator",
          [
             "administrator",
             "computer",
             "ict",
             "network",
             "professionals",
             "support"
          ]
       ],
       [
          "Network Analyst",
          [
             "analyst",
             "computer",
             "ict",
             "network",
             "professionals",
             "support"
          ]
       ],
       [
          "Neurologist",
          [
             "health",
             "medical",
             "neurologist",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Neurosurgeon",
          [
             "health",
             "medical",
             "neurosurgeon",
             "practitioners",
             "professionals",
             "surgeons"
          ]
       ],
       [
          "Newspaper or Periodical Editor",
          [
             "arts",
             "editor",
             "journalists",
             "media",
             "newspaper",
             "other",
             "periodical",
             "professionals",
             "writers"
          ]
       ],
       [
          "Noxious Weeds and Pest Inspector",
          [
             "administrative",
             "clerical",
             "inspector",
             "inspectors",
             "miscellaneous",
             "noxious",
             "officers",
             "other",
             "pest",
             "regulatory",
             "weeds",
             "workers"
          ]
       ],
       [
          "Nuclear Medicine Technologist",
          [
             "diagnostic",
             "health",
             "imaging",
             "medical",
             "medicine",
             "nuclear",
             "professionals",
             "promotion",
             "technologist"
          ]
       ],
       [
          "Nurse Educator",
          [
             "educator",
             "educators",
             "health",
             "midwifery",
             "nurse",
             "nursing",
             "professionals",
             "researchers"
          ]
       ],
       [
          "Nurse Manager",
          [
             "health",
             "manager",
             "midwifery",
             "nurse",
             "nursing",
             "professionals"
          ]
       ],
       [
          "Nurse Practitioner",
          [
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "practitioner",
             "professionals",
             "registered"
          ]
       ],
       [
          "Nurse Researcher",
          [
             "educators",
             "health",
             "midwifery",
             "nurse",
             "nursing",
             "professionals",
             "researchers"
          ]
       ],
       [
          "Nurseryperson",
          [
             "animal",
             "horticultural",
             "nurseryperson",
             "skilled",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Nursing Clinical Director",
          [
             "clinical",
             "director",
             "education",
             "health",
             "managers",
             "nursing",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Nursing Support Worker",
          [
             "aides",
             "assistants",
             "care",
             "carers",
             "community",
             "nursing",
             "personal",
             "service",
             "support",
             "workers"
          ]
       ],
       [
          "Obstetrician and Gynaecologist",
          [
             "gynaecologist",
             "health",
             "medical",
             "obstetrician",
             "other",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Occupational Health and Safety Adviser",
          [
             "adviser",
             "diagnostic",
             "environmental",
             "health",
             "occupational",
             "professionals",
             "promotion",
             "safety"
          ]
       ],
       [
          "Occupational Therapist",
          [
             "health",
             "occupational",
             "professionals",
             "therapist",
             "therapy"
          ]
       ],
       [
          "Office Cashier",
          [
             "cashier",
             "cashiers",
             "checkout",
             "office",
             "operators",
             "sales",
             "support",
             "workers"
          ]
       ],
       [
          "Office Manager",
          [
             "administrative",
             "administrators",
             "clerical",
             "manager",
             "managers",
             "office",
             "practice",
             "program",
             "workers"
          ]
       ],
       [
          "Operating Theatre Technician",
          [
             "agricultural",
             "engineering",
             "ict",
             "medical",
             "operating",
             "science",
             "technician",
             "technicians",
             "theatre",
             "trades",
             "workers"
          ]
       ],
       [
          "Ophthalmologist",
          [
             "health",
             "medical",
             "ophthalmologist",
             "other",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Optical Dispenser",
          [
             "dispenser",
             "miscellaneous",
             "optical",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Optical Mechanic",
          [
             "mechanic",
             "miscellaneous",
             "optical",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Optometrist",
          [
             "diagnostic",
             "health",
             "optometrist",
             "optometrists",
             "orthoptists",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Order Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "logistics",
             "order",
             "other",
             "purchasing",
             "supply",
             "workers"
          ]
       ],
       [
          "Organisation and Methods Analyst",
          [
             "analyst",
             "analysts",
             "business",
             "human",
             "information",
             "management",
             "marketing",
             "methods",
             "organisation",
             "professionals",
             "resource"
          ]
       ],
       [
          "Organisational Psychologist",
          [
             "legal",
             "organisational",
             "professionals",
             "psychologist",
             "psychologists",
             "social",
             "welfare"
          ]
       ],
       [
          "Orthopaedic Surgeon",
          [
             "health",
             "medical",
             "orthopaedic",
             "practitioners",
             "professionals",
             "surgeons"
          ]
       ],
       [
          "Orthoptist",
          [
             "diagnostic",
             "health",
             "optometrists",
             "orthoptist",
             "orthoptists",
             "professionals",
             "promotion"
          ]
       ],
       [
          "Orthotist or Prosthetist",
          [
             "diagnostic",
             "health",
             "orthotist",
             "other",
             "professionals",
             "promotion",
             "prosthetist"
          ]
       ],
       [
          "Osteopath",
          [
             "chiropractors",
             "health",
             "osteopath",
             "osteopaths",
             "professionals",
             "therapy"
          ]
       ],
       [
          "Other Sales Support Worker",
          [
             "miscellaneous",
             "other",
             "sales",
             "support",
             "workers"
          ]
       ],
       [
          "Other Spatial Scientist",
          [
             "architects",
             "design",
             "designers",
             "engineering",
             "other",
             "planners",
             "professionals",
             "science",
             "scientist",
             "scientists",
             "spatial",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Other Sports Coach or Instructor",
          [
             "coach",
             "coaches",
             "community",
             "fitness",
             "instructor",
             "instructors",
             "officials",
             "other",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Other Sports Official",
          [
             "coaches",
             "community",
             "fitness",
             "instructors",
             "official",
             "officials",
             "other",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Other Wood Processing Machine Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "paper",
             "plant",
             "processing",
             "stationary",
             "wood"
          ]
       ],
       [
          "Otorhinolaryngologist",
          [
             "health",
             "medical",
             "otorhinolaryngologist",
             "practitioners",
             "professionals",
             "surgeons"
          ]
       ],
       [
          "Out of School Hours Care Worker",
          [
             "aides",
             "care",
             "carers",
             "child",
             "community",
             "hours",
             "of",
             "out",
             "personal",
             "school",
             "service",
             "workers"
          ]
       ],
       [
          "Outdoor Adventure Guides",
          [
             "adventure",
             "community",
             "fitness",
             "guides",
             "outdoor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Outdoor Adventure Instructor",
          [
             "adventure",
             "community",
             "fitness",
             "guides",
             "instructor",
             "outdoor",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Packers",
          [
             "assemblers",
             "factory",
             "labourers",
             "packers",
             "process",
             "product",
             "workers"
          ]
       ],
       [
          "Paediatric Surgeon",
          [
             "health",
             "medical",
             "paediatric",
             "practitioners",
             "professionals",
             "surgeons"
          ]
       ],
       [
          "Paediatrician",
          [
             "health",
             "medical",
             "paediatrician",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Painter (Visual Arts)",
          [
             "arts",
             "crafts",
             "media",
             "painter",
             "professionals",
             "visual"
          ]
       ],
       [
          "Painting Trades Worker",
          [
             "construction",
             "finishers",
             "floor",
             "painting",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Panelbeater",
          [
             "automotive",
             "body",
             "builders",
             "engineering",
             "painters",
             "panelbeater",
             "panelbeaters",
             "technicians",
             "trades",
             "trimmers",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Paper Products Machine Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "paper",
             "plant",
             "processing",
             "products",
             "stationary",
             "wood"
          ]
       ],
       [
          "Paper and Pulp Mill Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "mill",
             "operator",
             "operators",
             "other",
             "paper",
             "plant",
             "pulp",
             "stationary"
          ]
       ],
       [
          "Paper and Pulp Mill Worker",
          [
             "factory",
             "labourers",
             "mill",
             "miscellaneous",
             "paper",
             "process",
             "pulp",
             "timber",
             "wood",
             "workers"
          ]
       ],
       [
          "Park Ranger",
          [
             "design",
             "engineering",
             "environmental",
             "natural",
             "park",
             "physical",
             "professionals",
             "ranger",
             "science",
             "scientists",
             "transport"
          ]
       ],
       [
          "Parking Inspector",
          [
             "administrative",
             "clerical",
             "inspector",
             "office",
             "other",
             "parking",
             "support",
             "workers"
          ]
       ],
       [
          "Parole or Probation Officer",
          [
             "community",
             "health",
             "officer",
             "parole",
             "personal",
             "probation",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Passenger Coach Driver",
          [
             "automobile",
             "bus",
             "coach",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "passenger",
             "rail",
             "road"
          ]
       ],
       [
          "Pastrycook",
          [
             "bakers",
             "food",
             "pastrycook",
             "pastrycooks",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Pastrycook's Assistant",
          [
             "assistant",
             "assistants",
             "food",
             "labourers",
             "pastrycooks",
             "preparation",
             "trades"
          ]
       ],
       [
          "Patents Examiner",
          [
             "business",
             "examiner",
             "human",
             "information",
             "marketing",
             "organisation",
             "other",
             "patents",
             "professionals",
             "resource"
          ]
       ],
       [
          "Pathologist",
          [
             "health",
             "medical",
             "other",
             "pathologist",
             "practitioners",
             "professionals"
          ]
       ],
       [
          "Pathology Collector",
          [
             "agricultural",
             "collector",
             "engineering",
             "ict",
             "medical",
             "pathology",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Paving Plant Operator",
          [
             "drivers",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "other",
             "paving",
             "plant"
          ]
       ],
       [
          "Paving and Surfacing Labourer",
          [
             "construction",
             "labourer",
             "labourers",
             "mining",
             "paving",
             "surfacing"
          ]
       ],
       [
          "Payroll Clerk",
          [
             "accounting",
             "administrative",
             "bookkeepers",
             "clerical",
             "clerk",
             "clerks",
             "numerical",
             "payroll",
             "workers"
          ]
       ],
       [
          "Performing Arts Technicians",
          [
             "arts",
             "miscellaneous",
             "other",
             "performing",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Personal Assistant",
          [
             "administrative",
             "assistant",
             "assistants",
             "clerical",
             "personal",
             "secretaries",
             "workers"
          ]
       ],
       [
          "Personal Care Assistant",
          [
             "aides",
             "assistant",
             "assistants",
             "care",
             "carers",
             "community",
             "nursing",
             "personal",
             "service",
             "support",
             "workers"
          ]
       ],
       [
          "Personal Service Workers",
          [
             "community",
             "other",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Pest Controller",
          [
             "controller",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "other",
             "pest",
             "workers"
          ]
       ],
       [
          "Pet Groomer",
          [
             "animal",
             "attendants",
             "groomer",
             "horticultural",
             "pet",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainers",
             "workers"
          ]
       ],
       [
          "Petroleum Engineer",
          [
             "design",
             "engineer",
             "engineering",
             "engineers",
             "mining",
             "petroleum",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Pharmacy Sales Assistant",
          [
             "assistant",
             "assistants",
             "pharmacy",
             "sales",
             "salespersons",
             "workers"
          ]
       ],
       [
          "Pharmacy Technician",
          [
             "agricultural",
             "engineering",
             "ict",
             "medical",
             "pharmacy",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Photographer",
          [
             "arts",
             "media",
             "photographer",
             "professionals"
          ]
       ],
       [
          "Photographer's Assistant",
          [
             "assistant",
             "miscellaneous",
             "other",
             "photographers",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Photographic Developer and Printer",
          [
             "developer",
             "drivers",
             "machine",
             "machinery",
             "operators",
             "photographic",
             "plant",
             "printer",
             "stationary"
          ]
       ],
       [
          "Physicist",
          [
             "design",
             "engineering",
             "natural",
             "other",
             "physical",
             "physicist",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Physiotherapist",
          [
             "health",
             "physiotherapist",
             "professionals",
             "therapy"
          ]
       ],
       [
          "Picture Framer",
          [
             "framer",
             "machinists",
             "other",
             "picture",
             "technicians",
             "trades",
             "wood",
             "workers"
          ]
       ],
       [
          "Pig Farmer",
          [
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers",
             "pig"
          ]
       ],
       [
          "Plastic Cablemaking Machine Operator",
          [
             "cablemaking",
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "plastic",
             "plastics",
             "production",
             "rubber",
             "stationary"
          ]
       ],
       [
          "Plastic Compounding and Reclamation Machine Operator",
          [
             "compounding",
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "plastic",
             "plastics",
             "production",
             "reclamation",
             "rubber",
             "stationary"
          ]
       ],
       [
          "Plastic and Reconstructive Surgeon",
          [
             "health",
             "medical",
             "plastic",
             "practitioners",
             "professionals",
             "reconstructive",
             "surgeons"
          ]
       ],
       [
          "Plastics Fabricator or Welder",
          [
             "drivers",
             "fabricator",
             "machine",
             "machinery",
             "operators",
             "plant",
             "plastics",
             "production",
             "rubber",
             "stationary",
             "welder"
          ]
       ],
       [
          "Plastics Factory Worker",
          [
             "factory",
             "labourers",
             "miscellaneous",
             "plastics",
             "process",
             "rubber",
             "workers"
          ]
       ],
       [
          "Plastics Production Machine Operator (General)",
          [
             "drivers",
             "general",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "plastics",
             "production",
             "rubber",
             "stationary"
          ]
       ],
       [
          "Plastics Technician",
          [
             "miscellaneous",
             "other",
             "plastics",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Plastics and Rubber Production Machine Operators",
          [
             "drivers",
             "machine",
             "machinery",
             "operators",
             "plant",
             "plastics",
             "production",
             "rubber",
             "stationary"
          ]
       ],
       [
          "Plumber (General)",
          [
             "construction",
             "general",
             "plumber",
             "plumbers",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Plumber's Assistant",
          [
             "assistant",
             "building",
             "construction",
             "labourers",
             "mining",
             "plumbers",
             "plumbing"
          ]
       ],
       [
          "Plumbing Inspector",
          [
             "architectural",
             "building",
             "engineering",
             "ict",
             "inspector",
             "plumbing",
             "science",
             "surveying",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Podiatrist",
          [
             "health",
             "podiatrist",
             "professionals",
             "therapy"
          ]
       ],
       [
          "Police Officer",
          [
             "community",
             "defence",
             "fighters",
             "fire",
             "force",
             "members",
             "officer",
             "personal",
             "police",
             "protective",
             "service",
             "workers"
          ]
       ],
       [
          "Policy Analyst",
          [
             "analyst",
             "analysts",
             "business",
             "human",
             "information",
             "intelligence",
             "marketing",
             "organisation",
             "policy",
             "professionals",
             "resource"
          ]
       ],
       [
          "Policy and Planning Manager",
          [
             "administration",
             "business",
             "manager",
             "managers",
             "planning",
             "policy",
             "specialist"
          ]
       ],
       [
          "Post Office Manager",
          [
             "hospitality",
             "manager",
             "managers",
             "office",
             "post",
             "retail",
             "service"
          ]
       ],
       [
          "Postal Delivery Officer",
          [
             "administrative",
             "clerical",
             "couriers",
             "deliverers",
             "delivery",
             "office",
             "officer",
             "postal",
             "support",
             "workers"
          ]
       ],
       [
          "Postal Sorting Officer",
          [
             "administrative",
             "clerical",
             "mail",
             "office",
             "officer",
             "postal",
             "sorters",
             "sorting",
             "support",
             "workers"
          ]
       ],
       [
          "Potter or Ceramic Artist",
          [
             "artist",
             "arts",
             "ceramic",
             "crafts",
             "media",
             "potter",
             "professionals",
             "visual"
          ]
       ],
       [
          "Poultry Farm Worker",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "poultry",
             "workers"
          ]
       ],
       [
          "Poultry Farmer",
          [
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers",
             "poultry"
          ]
       ],
       [
          "Poultry Process Worker",
          [
             "factory",
             "food",
             "labourers",
             "meat",
             "poultry",
             "process",
             "seafood",
             "workers"
          ]
       ],
       [
          "Power Generation Plant Operator",
          [
             "chemical",
             "gas",
             "generation",
             "miscellaneous",
             "operator",
             "operators",
             "other",
             "petroleum",
             "plant",
             "power",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Practice Managers",
          [
             "administrative",
             "administrators",
             "clerical",
             "managers",
             "office",
             "practice",
             "program",
             "workers"
          ]
       ],
       [
          "Precision Instrument Maker and Repairer",
          [
             "automotive",
             "engineering",
             "instrument",
             "maker",
             "mechanical",
             "metal",
             "precision",
             "repairer",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Preschool Aide",
          [
             "aide",
             "aides",
             "carers",
             "community",
             "education",
             "personal",
             "preschool",
             "service",
             "workers"
          ]
       ],
       [
          "Pressure Welder",
          [
             "automotive",
             "engineering",
             "fabrication",
             "pressure",
             "steel",
             "structural",
             "technicians",
             "trades",
             "welder",
             "welding",
             "workers"
          ]
       ],
       [
          "Primary Health Organisation Manager",
          [
             "education",
             "health",
             "manager",
             "managers",
             "organisation",
             "primary",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Primary Products Inspectors",
          [
             "agricultural",
             "engineering",
             "ict",
             "inspectors",
             "medical",
             "primary",
             "products",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Primary School Teacher",
          [
             "education",
             "primary",
             "professionals",
             "school",
             "teachers"
          ]
       ],
       [
          "Print Finisher",
          [
             "finisher",
             "finishers",
             "other",
             "print",
             "printers",
             "printing",
             "screen",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Print Journalist",
          [
             "arts",
             "journalist",
             "journalists",
             "media",
             "other",
             "print",
             "professionals",
             "writers"
          ]
       ],
       [
          "Printer's Assistant",
          [
             "assistant",
             "assistants",
             "labourers",
             "miscellaneous",
             "other",
             "printers",
             "printing",
             "table",
             "workers"
          ]
       ],
       [
          "Printing Machinist",
          [
             "machinist",
             "other",
             "printers",
             "printing",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Printing Table Worker",
          [
             "assistants",
             "labourers",
             "miscellaneous",
             "other",
             "printing",
             "table",
             "workers"
          ]
       ],
       [
          "Prison Officer",
          [
             "community",
             "officer",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Private Investigator",
          [
             "community",
             "guards",
             "investigator",
             "officers",
             "personal",
             "prison",
             "private",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Private Tutors and Teachers",
          [
             "education",
             "miscellaneous",
             "private",
             "professionals",
             "teachers",
             "tutors"
          ]
       ],
       [
          "Product Assembler",
          [
             "assembler",
             "assemblers",
             "factory",
             "labourers",
             "packers",
             "process",
             "product",
             "workers"
          ]
       ],
       [
          "Product Examiner",
          [
             "controllers",
             "examiner",
             "factory",
             "labourers",
             "miscellaneous",
             "process",
             "product",
             "quality",
             "workers"
          ]
       ],
       [
          "Product Grader",
          [
             "controllers",
             "factory",
             "grader",
             "labourers",
             "miscellaneous",
             "process",
             "product",
             "quality",
             "workers"
          ]
       ],
       [
          "Product Tester",
          [
             "controllers",
             "factory",
             "labourers",
             "miscellaneous",
             "process",
             "product",
             "quality",
             "tester",
             "workers"
          ]
       ],
       [
          "Production Assistant (Film, Television, Radio or Stage)",
          [
             "administrative",
             "assistant",
             "clerical",
             "film",
             "miscellaneous",
             "other",
             "production",
             "radio",
             "stage",
             "television",
             "workers"
          ]
       ],
       [
          "Production Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "logistics",
             "other",
             "production",
             "purchasing",
             "supply",
             "workers"
          ]
       ],
       [
          "Production Manager (Forestry)",
          [
             "construction",
             "distribution",
             "forestry",
             "manager",
             "managers",
             "production",
             "specialist"
          ]
       ],
       [
          "Production Manager (Manufacturing)",
          [
             "construction",
             "distribution",
             "manager",
             "managers",
             "manufacturing",
             "production",
             "specialist"
          ]
       ],
       [
          "Production Manager (Mining)",
          [
             "construction",
             "distribution",
             "manager",
             "managers",
             "mining",
             "production",
             "specialist"
          ]
       ],
       [
          "Production or Plant Engineer",
          [
             "design",
             "engineer",
             "engineering",
             "engineers",
             "industrial",
             "mechanical",
             "plant",
             "production",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Program Director (Television or Radio)",
          [
             "arts",
             "director",
             "directors",
             "film",
             "media",
             "professionals",
             "program",
             "radio",
             "stage",
             "television"
          ]
       ],
       [
          "Program or Project Administrator",
          [
             "administrative",
             "administrator",
             "administrators",
             "clerical",
             "contract",
             "managers",
             "office",
             "program",
             "project",
             "workers"
          ]
       ],
       [
          "Project Builder",
          [
             "builder",
             "construction",
             "distribution",
             "managers",
             "production",
             "project",
             "specialist"
          ]
       ],
       [
          "Proof Reader",
          [
             "administrative",
             "clerical",
             "miscellaneous",
             "other",
             "proof",
             "reader",
             "workers"
          ]
       ],
       [
          "Property Manager",
          [
             "agents",
             "estate",
             "manager",
             "property",
             "real",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Psychiatrist",
          [
             "health",
             "medical",
             "practitioners",
             "professionals",
             "psychiatrist"
          ]
       ],
       [
          "Psychologists",
          [
             "legal",
             "professionals",
             "psychologists",
             "social",
             "welfare"
          ]
       ],
       [
          "Psychotherapist",
          [
             "legal",
             "professionals",
             "psychologists",
             "psychotherapist",
             "social",
             "welfare"
          ]
       ],
       [
          "Public Relations Manager",
          [
             "advertising",
             "manager",
             "managers",
             "public",
             "relations",
             "sales",
             "specialist"
          ]
       ],
       [
          "Public Relations Professional",
          [
             "business",
             "human",
             "marketing",
             "professional",
             "professionals",
             "public",
             "relations",
             "resource",
             "sales"
          ]
       ],
       [
          "Purchasing Officer",
          [
             "administrative",
             "clerical",
             "clerks",
             "logistics",
             "officer",
             "other",
             "purchasing",
             "supply",
             "workers"
          ]
       ],
       [
          "Quality Assurance Manager",
          [
             "assurance",
             "manager",
             "managers",
             "miscellaneous",
             "other",
             "quality",
             "specialist"
          ]
       ],
       [
          "Quantity Surveyor",
          [
             "civil",
             "design",
             "engineering",
             "professionals",
             "quantity",
             "science",
             "surveyor",
             "transport"
          ]
       ],
       [
          "Quarantine Officer",
          [
             "agricultural",
             "engineering",
             "ict",
             "inspectors",
             "medical",
             "officer",
             "primary",
             "products",
             "quarantine",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Radiation Oncologist",
          [
             "health",
             "medical",
             "oncologist",
             "other",
             "practitioners",
             "professionals",
             "radiation"
          ]
       ],
       [
          "Radiator Repairer",
          [
             "accessories",
             "fitters",
             "labourers",
             "miscellaneous",
             "motor",
             "other",
             "parts",
             "radiator",
             "repairer",
             "vehicle"
          ]
       ],
       [
          "Radio Despatcher",
          [
             "administrative",
             "clerical",
             "despatcher",
             "miscellaneous",
             "other",
             "radio",
             "workers"
          ]
       ],
       [
          "Radio Journalist",
          [
             "arts",
             "journalist",
             "journalists",
             "media",
             "other",
             "professionals",
             "radio",
             "writers"
          ]
       ],
       [
          "Radio Presenter",
          [
             "artistic",
             "arts",
             "directors",
             "media",
             "presenter",
             "presenters",
             "producers",
             "professionals",
             "radio"
          ]
       ],
       [
          "Radiocommunications Technician",
          [
             "engineering",
             "ict",
             "radiocommunications",
             "science",
             "specialists",
             "technical",
             "technician",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Railway Signal Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "railway",
             "signal",
             "stationary"
          ]
       ],
       [
          "Railway Station Manager",
          [
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "railway",
             "retail",
             "service",
             "services",
             "station",
             "transport"
          ]
       ],
       [
          "Railway Track Plant Operator",
          [
             "drivers",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "other",
             "plant",
             "railway",
             "track"
          ]
       ],
       [
          "Railway Track Worker",
          [
             "construction",
             "labourers",
             "mining",
             "railway",
             "track",
             "worker"
          ]
       ],
       [
          "Railways Assistant",
          [
             "assistant",
             "labourers",
             "miscellaneous",
             "other",
             "railways"
          ]
       ],
       [
          "Real Estate Agency Principal",
          [
             "agency",
             "agents",
             "estate",
             "principal",
             "real",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Real Estate Agent",
          [
             "agent",
             "agents",
             "estate",
             "real",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Real Estate Representative",
          [
             "agents",
             "estate",
             "real",
             "representative",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Receptionist (General)",
          [
             "administrative",
             "clerical",
             "clerks",
             "general",
             "inquiry",
             "receptionist",
             "receptionists",
             "workers"
          ]
       ],
       [
          "Records Manager",
          [
             "archivists",
             "business",
             "curators",
             "human",
             "information",
             "manager",
             "managers",
             "marketing",
             "organisation",
             "professionals",
             "records",
             "resource"
          ]
       ],
       [
          "Recreation Officer",
          [
             "arts",
             "community",
             "legal",
             "officer",
             "professionals",
             "recreation",
             "social",
             "welfare",
             "workers"
          ]
       ],
       [
          "Recruitment Consultant",
          [
             "business",
             "consultant",
             "human",
             "marketing",
             "professionals",
             "recruitment",
             "resource",
             "training"
          ]
       ],
       [
          "Recycling Worker",
          [
             "factory",
             "labourers",
             "miscellaneous",
             "other",
             "process",
             "recycling",
             "workers"
          ]
       ],
       [
          "Recycling or Rubbish Collector",
          [
             "collector",
             "labourers",
             "miscellaneous",
             "other",
             "recycling",
             "rubbish"
          ]
       ],
       [
          "Refuge Worker",
          [
             "aides",
             "assistants",
             "care",
             "carers",
             "community",
             "personal",
             "refuge",
             "service",
             "special",
             "workers"
          ]
       ],
       [
          "Regional Education Manager",
          [
             "education",
             "health",
             "manager",
             "managers",
             "other",
             "regional",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Registered Nurse (Aged Care)",
          [
             "aged",
             "care",
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Child and Family Health)",
          [
             "child",
             "family",
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Community Health)",
          [
             "community",
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Critical Care and Emergency)",
          [
             "care",
             "critical",
             "emergency",
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Developmental Disability)",
          [
             "developmental",
             "disability",
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Disability and Rehabilitation)",
          [
             "disability",
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered",
             "rehabilitation"
          ]
       ],
       [
          "Registered Nurse (Medical Practice)",
          [
             "health",
             "medical",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "practice",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Medical)",
          [
             "health",
             "medical",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Mental Health)",
          [
             "health",
             "mental",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Perioperative)",
          [
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "perioperative",
             "professionals",
             "registered"
          ]
       ],
       [
          "Registered Nurse (Surgical)",
          [
             "health",
             "midwifery",
             "nurse",
             "nurses",
             "nursing",
             "professionals",
             "registered",
             "surgical"
          ]
       ],
       [
          "Registered Nurses",
          [
             "health",
             "midwifery",
             "nurses",
             "nursing",
             "professionals",
             "registered"
          ]
       ],
       [
          "Rehabilitation Counsellor",
          [
             "counsellor",
             "counsellors",
             "legal",
             "professionals",
             "rehabilitation",
             "social",
             "welfare"
          ]
       ],
       [
          "Reinforced Plastic and Composite Production Worker",
          [
             "composite",
             "drivers",
             "machine",
             "machinery",
             "operators",
             "plant",
             "plastic",
             "plastics",
             "production",
             "reinforced",
             "rubber",
             "stationary",
             "worker"
          ]
       ],
       [
          "Religious Assistant",
          [
             "assistant",
             "community",
             "other",
             "personal",
             "religious",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Renal Medicine Specialist",
          [
             "health",
             "medical",
             "medicine",
             "physicians",
             "practitioners",
             "professionals",
             "renal",
             "specialist"
          ]
       ],
       [
          "Rental Salesperson",
          [
             "assistants",
             "other",
             "rental",
             "sales",
             "salesperson",
             "salespersons",
             "workers"
          ]
       ],
       [
          "Research and Development Manager",
          [
             "administration",
             "business",
             "development",
             "manager",
             "managers",
             "research",
             "specialist"
          ]
       ],
       [
          "Resident Medical Officer",
          [
             "generalist",
             "health",
             "medical",
             "officer",
             "practitioners",
             "professionals",
             "resident"
          ]
       ],
       [
          "Residential Care Officer",
          [
             "care",
             "community",
             "health",
             "officer",
             "personal",
             "residential",
             "service",
             "support",
             "welfare",
             "workers"
          ]
       ],
       [
          "Retail Buyer",
          [
             "buyer",
             "buyers",
             "miscellaneous",
             "retail",
             "sales",
             "support",
             "wool",
             "workers"
          ]
       ],
       [
          "Retail Loss Prevention Officer",
          [
             "community",
             "guards",
             "loss",
             "officer",
             "officers",
             "personal",
             "prevention",
             "prison",
             "protective",
             "retail",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Retail Manager (General)",
          [
             "general",
             "hospitality",
             "manager",
             "managers",
             "retail",
             "service"
          ]
       ],
       [
          "Retail Pharmacist",
          [
             "diagnostic",
             "health",
             "pharmacist",
             "pharmacists",
             "professionals",
             "promotion",
             "retail"
          ]
       ],
       [
          "Retail Supervisor",
          [
             "assistants",
             "retail",
             "sales",
             "salespersons",
             "supervisor",
             "workers"
          ]
       ],
       [
          "Retirement Village Manager",
          [
             "accommodation",
             "hospitality",
             "manager",
             "managers",
             "other",
             "retail",
             "retirement",
             "service",
             "village"
          ]
       ],
       [
          "Rheumatologist",
          [
             "health",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "rheumatologist",
             "specialist"
          ]
       ],
       [
          "Road Roller Operator",
          [
             "drivers",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "other",
             "plant",
             "road",
             "roller"
          ]
       ],
       [
          "Road Traffic Controller",
          [
             "controller",
             "labourers",
             "miscellaneous",
             "other",
             "road",
             "traffic"
          ]
       ],
       [
          "Roof Plumber",
          [
             "construction",
             "plumber",
             "plumbers",
             "roof",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Roof Tiler",
          [
             "construction",
             "glaziers",
             "plasterers",
             "roof",
             "technicians",
             "tiler",
             "tilers",
             "trades",
             "workers"
          ]
       ],
       [
          "Rubber Factory Worker",
          [
             "factory",
             "labourers",
             "miscellaneous",
             "plastics",
             "process",
             "rubber",
             "workers"
          ]
       ],
       [
          "Rubber Production Machine Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "plastics",
             "production",
             "rubber",
             "stationary"
          ]
       ],
       [
          "Safety Inspector",
          [
             "building",
             "engineering",
             "ict",
             "inspector",
             "safety",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Sail Maker",
          [
             "canvas",
             "clothing",
             "footwear",
             "goods",
             "leather",
             "maker",
             "makers",
             "other",
             "sail",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Sales Assistant (General)",
          [
             "assistant",
             "assistants",
             "general",
             "sales",
             "salespersons",
             "workers"
          ]
       ],
       [
          "Sales Assistants and Salespersons",
          [
             "assistants",
             "other",
             "sales",
             "salespersons",
             "workers"
          ]
       ],
       [
          "Sales Demonstrator",
          [
             "demonstrator",
             "demonstrators",
             "miscellaneous",
             "models",
             "sales",
             "support",
             "workers"
          ]
       ],
       [
          "Sales Representative (Building and Plumbing Supplies)",
          [
             "agents",
             "building",
             "insurance",
             "plumbing",
             "representative",
             "representatives",
             "sales",
             "supplies",
             "workers"
          ]
       ],
       [
          "Sales Representative (Business Services)",
          [
             "agents",
             "business",
             "insurance",
             "representative",
             "representatives",
             "sales",
             "services",
             "workers"
          ]
       ],
       [
          "Sales Representative (Industrial Products)",
          [
             "business",
             "human",
             "industrial",
             "marketing",
             "products",
             "professionals",
             "public",
             "relations",
             "representative",
             "representatives",
             "resource",
             "sales",
             "technical"
          ]
       ],
       [
          "Sales Representative (Medical and Pharmaceutical Products)",
          [
             "business",
             "human",
             "marketing",
             "medical",
             "pharmaceutical",
             "products",
             "professionals",
             "public",
             "relations",
             "representative",
             "representatives",
             "resource",
             "sales",
             "technical"
          ]
       ],
       [
          "Sales Representative (Motor Vehicle Parts and Accessories)",
          [
             "accessories",
             "agents",
             "insurance",
             "motor",
             "parts",
             "representative",
             "representatives",
             "sales",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Sales Representative (Personal and Household Goods)",
          [
             "agents",
             "goods",
             "household",
             "insurance",
             "personal",
             "representative",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Sales Representatives",
          [
             "agents",
             "insurance",
             "representatives",
             "sales",
             "workers"
          ]
       ],
       [
          "Sales and Marketing Manager",
          [
             "advertising",
             "manager",
             "managers",
             "marketing",
             "public",
             "relations",
             "sales",
             "specialist"
          ]
       ],
       [
          "Sand Blaster",
          [
             "blaster",
             "drivers",
             "machine",
             "machinery",
             "operators",
             "other",
             "plant",
             "sand",
             "stationary"
          ]
       ],
       [
          "Saw Maker and Repairer",
          [
             "automotive",
             "engineering",
             "maker",
             "mechanical",
             "metal",
             "precision",
             "repairer",
             "saw",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Sawmill or Timber Yard Worker",
          [
             "factory",
             "labourers",
             "miscellaneous",
             "process",
             "sawmill",
             "timber",
             "wood",
             "worker",
             "workers",
             "yard"
          ]
       ],
       [
          "Sawmilling Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "paper",
             "plant",
             "processing",
             "sawmilling",
             "stationary",
             "wood"
          ]
       ],
       [
          "Scaffolder",
          [
             "construction",
             "labourers",
             "mining",
             "scaffolder",
             "steel",
             "structural",
             "workers"
          ]
       ],
       [
          "School Laboratory Technician",
          [
             "agricultural",
             "engineering",
             "ict",
             "laboratory",
             "medical",
             "school",
             "science",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "School Principal",
          [
             "education",
             "health",
             "managers",
             "principal",
             "school",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Science Technicians",
          [
             "agricultural",
             "engineering",
             "ict",
             "medical",
             "science",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Screen Printer",
          [
             "finishers",
             "other",
             "print",
             "printer",
             "printers",
             "printing",
             "screen",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Sculptor",
          [
             "arts",
             "crafts",
             "media",
             "professionals",
             "sculptor",
             "visual"
          ]
       ],
       [
          "Seafood Packer",
          [
             "assemblers",
             "factory",
             "labourers",
             "packer",
             "packers",
             "process",
             "product",
             "seafood",
             "workers"
          ]
       ],
       [
          "Seafood Process Worker",
          [
             "factory",
             "food",
             "labourers",
             "meat",
             "poultry",
             "process",
             "seafood",
             "workers"
          ]
       ],
       [
          "Secondary School Teacher",
          [
             "education",
             "professionals",
             "school",
             "secondary",
             "teachers"
          ]
       ],
       [
          "Secretary (General)",
          [
             "administrative",
             "assistants",
             "clerical",
             "general",
             "personal",
             "secretaries",
             "secretary",
             "workers"
          ]
       ],
       [
          "Security Consultant",
          [
             "community",
             "consultant",
             "guards",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Security Officer",
          [
             "community",
             "guards",
             "officer",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Security Officers and Guards",
          [
             "community",
             "guards",
             "officers",
             "personal",
             "prison",
             "protective",
             "security",
             "service",
             "workers"
          ]
       ],
       [
          "Senior Non-commissioned Defence Force Member",
          [
             "defence",
             "force",
             "managers",
             "member",
             "miscellaneous",
             "noncommissioned",
             "senior",
             "specialist"
          ]
       ],
       [
          "Service Station Attendant",
          [
             "assistants",
             "attendant",
             "sales",
             "salespersons",
             "service",
             "station",
             "workers"
          ]
       ],
       [
          "Sewing Machinist",
          [
             "drivers",
             "machine",
             "machinery",
             "machinist",
             "operators",
             "plant",
             "sewing",
             "stationary"
          ]
       ],
       [
          "Sex Worker or Escort",
          [
             "community",
             "escort",
             "other",
             "personal",
             "service",
             "sex",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Shearer",
          [
             "animal",
             "attendants",
             "horticultural",
             "shearer",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainers",
             "workers"
          ]
       ],
       [
          "Sheep Farm Worker",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "sheep",
             "workers"
          ]
       ],
       [
          "Sheep Farmer",
          [
             "farm",
             "farmer",
             "farmers",
             "livestock",
             "managers",
             "sheep"
          ]
       ],
       [
          "Sheetmetal Trades Worker",
          [
             "automotive",
             "engineering",
             "fabrication",
             "sheetmetal",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Shelf Filler",
          [
             "filler",
             "fillers",
             "freight",
             "handlers",
             "labourers",
             "other",
             "shelf"
          ]
       ],
       [
          "Ship's Engineer",
          [
             "air",
             "design",
             "engineer",
             "engineering",
             "marine",
             "professionals",
             "science",
             "ships",
             "transport"
          ]
       ],
       [
          "Ship's Master",
          [
             "air",
             "design",
             "engineering",
             "marine",
             "master",
             "professionals",
             "science",
             "ships",
             "transport"
          ]
       ],
       [
          "Ship's Officer",
          [
             "air",
             "design",
             "engineering",
             "marine",
             "officer",
             "professionals",
             "science",
             "ships",
             "transport"
          ]
       ],
       [
          "Ship's Surveyor",
          [
             "air",
             "design",
             "engineering",
             "marine",
             "professionals",
             "science",
             "ships",
             "surveyor",
             "transport"
          ]
       ],
       [
          "Shipwright",
          [
             "boat",
             "builders",
             "miscellaneous",
             "other",
             "shipwright",
             "shipwrights",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Shoemaker",
          [
             "canvas",
             "clothing",
             "footwear",
             "goods",
             "leather",
             "makers",
             "other",
             "shoemaker",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Shot Firer",
          [
             "drillers",
             "drivers",
             "firer",
             "firers",
             "machine",
             "machinery",
             "miners",
             "operators",
             "plant",
             "shot",
             "stationary"
          ]
       ],
       [
          "Sign Erector",
          [
             "erector",
             "labourers",
             "miscellaneous",
             "other",
             "sign"
          ]
       ],
       [
          "Signwriter",
          [
             "miscellaneous",
             "other",
             "signwriter",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Singer",
          [
             "arts",
             "media",
             "music",
             "professionals",
             "singer"
          ]
       ],
       [
          "Slaughterer",
          [
             "boners",
             "factory",
             "food",
             "labourers",
             "meat",
             "process",
             "slaughterer",
             "slaughterers",
             "slicers",
             "workers"
          ]
       ],
       [
          "Small Engine Mechanic",
          [
             "automotive",
             "electricians",
             "engine",
             "engineering",
             "mechanic",
             "mechanics",
             "motor",
             "small",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Small Offset Printer",
          [
             "offset",
             "other",
             "printer",
             "printers",
             "printing",
             "small",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Snowsport Instructor",
          [
             "coaches",
             "community",
             "fitness",
             "instructor",
             "instructors",
             "officials",
             "personal",
             "service",
             "snowsport",
             "sports",
             "workers"
          ]
       ],
       [
          "Social Professionals",
          [
             "legal",
             "professionals",
             "social",
             "welfare"
          ]
       ],
       [
          "Social Security Assessor",
          [
             "administrative",
             "assessor",
             "clerical",
             "inspectors",
             "miscellaneous",
             "officers",
             "other",
             "regulatory",
             "security",
             "social",
             "workers"
          ]
       ],
       [
          "Social Worker",
          [
             "legal",
             "professionals",
             "social",
             "welfare",
             "worker"
          ]
       ],
       [
          "Software Engineer",
          [
             "analysts",
             "applications",
             "business",
             "engineer",
             "ict",
             "professionals",
             "programmers",
             "software",
             "systems"
          ]
       ],
       [
          "Software Tester",
          [
             "analysts",
             "applications",
             "business",
             "ict",
             "professionals",
             "programmers",
             "software",
             "systems",
             "tester"
          ]
       ],
       [
          "Software and Applications Programmers",
          [
             "analysts",
             "applications",
             "business",
             "ict",
             "professionals",
             "programmers",
             "software",
             "systems"
          ]
       ],
       [
          "Solicitor",
          [
             "legal",
             "professionals",
             "social",
             "solicitor",
             "welfare"
          ]
       ],
       [
          "Solid Plasterer",
          [
             "construction",
             "glaziers",
             "plasterer",
             "plasterers",
             "solid",
             "technicians",
             "tilers",
             "trades",
             "workers"
          ]
       ],
       [
          "Sonographer",
          [
             "diagnostic",
             "health",
             "imaging",
             "medical",
             "professionals",
             "promotion",
             "sonographer"
          ]
       ],
       [
          "Sound Technician",
          [
             "arts",
             "miscellaneous",
             "other",
             "performing",
             "sound",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Special Education Teachers",
          [
             "education",
             "professionals",
             "school",
             "special",
             "teachers"
          ]
       ],
       [
          "Special Needs Teacher",
          [
             "education",
             "needs",
             "professionals",
             "school",
             "special",
             "teachers"
          ]
       ],
       [
          "Specialist Managers",
          [
             "managers",
             "miscellaneous",
             "other",
             "specialist"
          ]
       ],
       [
          "Specialist Physician (General Medicine)",
          [
             "general",
             "health",
             "medical",
             "medicine",
             "physician",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Specialist Physicians",
          [
             "health",
             "medical",
             "physicians",
             "practitioners",
             "professionals",
             "specialist"
          ]
       ],
       [
          "Speech Pathologist",
          [
             "audiologists",
             "health",
             "pathologist",
             "professionals",
             "speech",
             "therapy"
          ]
       ],
       [
          "Sports Administrator",
          [
             "administrator",
             "managers",
             "miscellaneous",
             "other",
             "specialist",
             "sports"
          ]
       ],
       [
          "Sports Centre Manager",
          [
             "amusement",
             "centre",
             "fitness",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service",
             "sports"
          ]
       ],
       [
          "Sports Development Officer",
          [
             "coaches",
             "community",
             "development",
             "fitness",
             "instructors",
             "officer",
             "officials",
             "personal",
             "service",
             "sports",
             "workers"
          ]
       ],
       [
          "Sports Umpire",
          [
             "coaches",
             "community",
             "fitness",
             "instructors",
             "officials",
             "personal",
             "service",
             "sports",
             "umpire",
             "workers"
          ]
       ],
       [
          "Sportspersons",
          [
             "community",
             "fitness",
             "personal",
             "service",
             "sports",
             "sportspersons",
             "workers"
          ]
       ],
       [
          "Stablehand",
          [
             "farm",
             "forestry",
             "garden",
             "labourers",
             "livestock",
             "stablehand",
             "workers"
          ]
       ],
       [
          "Stage Manager",
          [
             "arts",
             "directors",
             "film",
             "manager",
             "media",
             "professionals",
             "radio",
             "stage",
             "television"
          ]
       ],
       [
          "Stationary Plant Operators",
          [
             "drivers",
             "machine",
             "machinery",
             "operators",
             "other",
             "plant",
             "stationary"
          ]
       ],
       [
          "Statistical Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "financial",
             "insurance",
             "market",
             "money",
             "numerical",
             "statistical",
             "workers"
          ]
       ],
       [
          "Statistician",
          [
             "actuaries",
             "business",
             "human",
             "information",
             "marketing",
             "mathematicians",
             "organisation",
             "professionals",
             "resource",
             "statisticians"
          ]
       ],
       [
          "Steel Fixer",
          [
             "construction",
             "fixer",
             "labourers",
             "mining",
             "steel",
             "structural",
             "workers"
          ]
       ],
       [
          "Sterilisation Technician",
          [
             "drivers",
             "machine",
             "machinery",
             "operators",
             "other",
             "plant",
             "stationary",
             "sterilisation",
             "technician"
          ]
       ],
       [
          "Stock Clerk",
          [
             "administrative",
             "clerical",
             "clerk",
             "clerks",
             "logistics",
             "other",
             "purchasing",
             "stock",
             "supply",
             "workers"
          ]
       ],
       [
          "Stock and Station Agent",
          [
             "agent",
             "agents",
             "auctioneers",
             "insurance",
             "representatives",
             "sales",
             "station",
             "stock",
             "workers"
          ]
       ],
       [
          "Stockbroking Dealer",
          [
             "advisers",
             "brokers",
             "business",
             "dealer",
             "dealers",
             "financial",
             "human",
             "investment",
             "marketing",
             "professionals",
             "resource",
             "stockbroking"
          ]
       ],
       [
          "Stone Processing Machine Operator",
          [
             "clay",
             "concrete",
             "drivers",
             "glass",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "processing",
             "stationary",
             "stone"
          ]
       ],
       [
          "Stonemason",
          [
             "bricklayers",
             "carpenters",
             "construction",
             "joiners",
             "stonemason",
             "stonemasons",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Storeperson",
          [
             "drivers",
             "machinery",
             "operators",
             "storeperson"
          ]
       ],
       [
          "Street Vendor",
          [
             "assistants",
             "related",
             "sales",
             "salespersons",
             "street",
             "vendor",
             "vendors",
             "workers"
          ]
       ],
       [
          "Streetsweeper Operator",
          [
             "drivers",
             "machinery",
             "mobile",
             "operator",
             "operators",
             "other",
             "plant",
             "streetsweeper"
          ]
       ],
       [
          "Structural Engineer",
          [
             "civil",
             "design",
             "engineer",
             "engineering",
             "professionals",
             "science",
             "structural",
             "transport"
          ]
       ],
       [
          "Structural Steel Erector",
          [
             "construction",
             "erector",
             "labourers",
             "mining",
             "steel",
             "structural",
             "workers"
          ]
       ],
       [
          "Student Counsellor",
          [
             "counsellor",
             "counsellors",
             "legal",
             "professionals",
             "social",
             "student",
             "welfare"
          ]
       ],
       [
          "Sugar Cane Grower",
          [
             "cane",
             "crop",
             "farm",
             "farmers",
             "grower",
             "managers",
             "sugar"
          ]
       ],
       [
          "Sugar Mill Worker",
          [
             "drink",
             "factory",
             "food",
             "labourers",
             "mill",
             "process",
             "sugar",
             "workers"
          ]
       ],
       [
          "Supply and Distribution Manager",
          [
             "construction",
             "distribution",
             "manager",
             "managers",
             "production",
             "specialist",
             "supply"
          ]
       ],
       [
          "Surgeon (General)",
          [
             "general",
             "health",
             "medical",
             "practitioners",
             "professionals",
             "surgeons"
          ]
       ],
       [
          "Survey Interviewer",
          [
             "administrative",
             "clerical",
             "interviewer",
             "office",
             "support",
             "survey",
             "workers"
          ]
       ],
       [
          "Surveying or Spatial Science Technician",
          [
             "architectural",
             "building",
             "engineering",
             "ict",
             "science",
             "spatial",
             "surveying",
             "technician",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Surveyor",
          [
             "architects",
             "design",
             "designers",
             "engineering",
             "planners",
             "professionals",
             "science",
             "scientists",
             "spatial",
             "surveyor",
             "surveyors",
             "transport"
          ]
       ],
       [
          "Surveyor's Assistant",
          [
             "assistant",
             "construction",
             "labourers",
             "mining",
             "other",
             "surveyors"
          ]
       ],
       [
          "Swimming Coach or Instructor",
          [
             "coach",
             "coaches",
             "community",
             "fitness",
             "instructor",
             "instructors",
             "officials",
             "personal",
             "service",
             "sports",
             "swimming",
             "workers"
          ]
       ],
       [
          "Switchboard Operator",
          [
             "administrative",
             "clerical",
             "office",
             "operator",
             "support",
             "switchboard",
             "workers"
          ]
       ],
       [
          "Systems Administrator",
          [
             "administrator",
             "administrators",
             "database",
             "ict",
             "professionals",
             "security",
             "specialists",
             "systems"
          ]
       ],
       [
          "Systems Analyst",
          [
             "analyst",
             "analysts",
             "business",
             "ict",
             "professionals",
             "programmers",
             "systems"
          ]
       ],
       [
          "Tanker Driver",
          [
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road",
             "tanker",
             "truck"
          ]
       ],
       [
          "Taxation Accountant",
          [
             "accountant",
             "accountants",
             "auditors",
             "business",
             "company",
             "human",
             "marketing",
             "professionals",
             "resource",
             "secretaries",
             "taxation"
          ]
       ],
       [
          "Taxation Inspector",
          [
             "administrative",
             "clerical",
             "inspector",
             "inspectors",
             "miscellaneous",
             "officers",
             "other",
             "regulatory",
             "taxation",
             "workers"
          ]
       ],
       [
          "Taxi Driver",
          [
             "automobile",
             "bus",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road",
             "taxi"
          ]
       ],
       [
          "Teacher of English to Speakers of Other Languages",
          [
             "education",
             "english",
             "languages",
             "miscellaneous",
             "of",
             "other",
             "professionals",
             "speakers",
             "teacher",
             "to"
          ]
       ],
       [
          "Teacher of the Hearing Impaired",
          [
             "education",
             "hearing",
             "impaired",
             "of",
             "professionals",
             "school",
             "special",
             "teacher",
             "teachers",
             "the"
          ]
       ],
       [
          "Teacher of the Sight Impaired",
          [
             "education",
             "impaired",
             "of",
             "professionals",
             "school",
             "sight",
             "special",
             "teacher",
             "teachers",
             "the"
          ]
       ],
       [
          "Teachers' Aide",
          [
             "aide",
             "aides",
             "carers",
             "community",
             "education",
             "personal",
             "service",
             "teachers",
             "workers"
          ]
       ],
       [
          "Technical Cable Jointer",
          [
             "cable",
             "distribution",
             "electrical",
             "electronics",
             "electrotechnology",
             "jointer",
             "technical",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Technical Director",
          [
             "arts",
             "director",
             "directors",
             "film",
             "media",
             "professionals",
             "radio",
             "stage",
             "technical",
             "television"
          ]
       ],
       [
          "Technical Sales Representatives",
          [
             "business",
             "human",
             "marketing",
             "professionals",
             "public",
             "relations",
             "representatives",
             "resource",
             "sales",
             "technical"
          ]
       ],
       [
          "Technical Writer",
          [
             "arts",
             "journalists",
             "media",
             "other",
             "professionals",
             "technical",
             "writers"
          ]
       ],
       [
          "Technicians and Trades Workers",
          [
             "miscellaneous",
             "other",
             "technicians",
             "trades",
             "workers"
          ]
       ],
       [
          "Telecommunications Cable Jointer",
          [
             "cable",
             "electronics",
             "electrotechnology",
             "jointer",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Telecommunications Engineer",
          [
             "engineer",
             "engineering",
             "ict",
             "network",
             "professionals",
             "support",
             "telecommunications"
          ]
       ],
       [
          "Telecommunications Field Engineer",
          [
             "engineer",
             "engineering",
             "field",
             "ict",
             "science",
             "specialists",
             "technical",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Telecommunications Linesworker",
          [
             "electronics",
             "electrotechnology",
             "linesworker",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Telecommunications Network Engineer",
          [
             "engineer",
             "engineering",
             "ict",
             "network",
             "professionals",
             "support",
             "telecommunications"
          ]
       ],
       [
          "Telecommunications Network Planner",
          [
             "engineering",
             "ict",
             "network",
             "planner",
             "science",
             "specialists",
             "technical",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Telecommunications Technical Officer or Technologist",
          [
             "engineering",
             "ict",
             "officer",
             "science",
             "specialists",
             "technical",
             "technicians",
             "technologist",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Telecommunications Technician",
          [
             "electronics",
             "electrotechnology",
             "technician",
             "technicians",
             "telecommunications",
             "trades",
             "workers"
          ]
       ],
       [
          "Telemarketer",
          [
             "miscellaneous",
             "sales",
             "support",
             "telemarketer",
             "workers"
          ]
       ],
       [
          "Telephone Betting Clerk",
          [
             "administrative",
             "betting",
             "clerical",
             "clerk",
             "clerks",
             "office",
             "support",
             "telephone",
             "workers"
          ]
       ],
       [
          "Television Equipment Operator",
          [
             "arts",
             "equipment",
             "miscellaneous",
             "operator",
             "other",
             "performing",
             "technicians",
             "television",
             "trades",
             "workers"
          ]
       ],
       [
          "Television Journalist",
          [
             "arts",
             "journalist",
             "journalists",
             "media",
             "other",
             "professionals",
             "television",
             "writers"
          ]
       ],
       [
          "Television Presenter",
          [
             "artistic",
             "arts",
             "directors",
             "media",
             "presenter",
             "presenters",
             "producers",
             "professionals",
             "television"
          ]
       ],
       [
          "Tennis Coach",
          [
             "coach",
             "coaches",
             "community",
             "fitness",
             "instructors",
             "officials",
             "personal",
             "service",
             "sports",
             "tennis",
             "workers"
          ]
       ],
       [
          "Textile Dyeing and Finishing Machine Operator",
          [
             "drivers",
             "dyeing",
             "finishing",
             "footwear",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "production",
             "stationary",
             "textile"
          ]
       ],
       [
          "Textile and Footwear Production Machine Operators",
          [
             "drivers",
             "footwear",
             "machine",
             "machinery",
             "operators",
             "plant",
             "production",
             "stationary",
             "textile"
          ]
       ],
       [
          "Textile, Clothing and Footwear Mechanic",
          [
             "automotive",
             "clothing",
             "engineering",
             "fitters",
             "footwear",
             "machinists",
             "mechanic",
             "mechanical",
             "metal",
             "technicians",
             "textile",
             "trades",
             "workers"
          ]
       ],
       [
          "Therapy Aide",
          [
             "aide",
             "aides",
             "assistants",
             "care",
             "carers",
             "community",
             "nursing",
             "personal",
             "service",
             "support",
             "therapy",
             "workers"
          ]
       ],
       [
          "Thoracic Medicine Specialist",
          [
             "health",
             "medical",
             "medicine",
             "physicians",
             "practitioners",
             "professionals",
             "specialist",
             "thoracic"
          ]
       ],
       [
          "Ticket Collector or Usher",
          [
             "collector",
             "labourers",
             "miscellaneous",
             "other",
             "ticket",
             "usher"
          ]
       ],
       [
          "Ticket Seller",
          [
             "miscellaneous",
             "sales",
             "salespersons",
             "seller",
             "support",
             "ticket",
             "workers"
          ]
       ],
       [
          "Toolmaker",
          [
             "automotive",
             "engineering",
             "mechanical",
             "patternmakers",
             "technicians",
             "toolmaker",
             "toolmakers",
             "trades",
             "workers"
          ]
       ],
       [
          "Tour Guide",
          [
             "community",
             "gallery",
             "guide",
             "guides",
             "museum",
             "personal",
             "service",
             "sports",
             "tour",
             "travel",
             "workers"
          ]
       ],
       [
          "Tourist Information Officer",
          [
             "advisers",
             "community",
             "information",
             "officer",
             "personal",
             "service",
             "sports",
             "tourism",
             "tourist",
             "travel",
             "workers"
          ]
       ],
       [
          "Tow Truck Driver",
          [
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road",
             "tow",
             "truck"
          ]
       ],
       [
          "Traditional Chinese Medicine Practitioner",
          [
             "chinese",
             "complementary",
             "health",
             "medicine",
             "practitioner",
             "professionals",
             "therapists",
             "therapy",
             "traditional"
          ]
       ],
       [
          "Train Controller",
          [
             "controller",
             "drivers",
             "machine",
             "machinery",
             "operators",
             "other",
             "plant",
             "stationary",
             "train"
          ]
       ],
       [
          "Train Driver",
          [
             "automobile",
             "bus",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road",
             "train",
             "tram"
          ]
       ],
       [
          "Train Examiner",
          [
             "administrative",
             "clerical",
             "examiner",
             "inspectors",
             "miscellaneous",
             "officers",
             "other",
             "regulatory",
             "train",
             "workers"
          ]
       ],
       [
          "Training and Development Professional",
          [
             "business",
             "development",
             "human",
             "marketing",
             "professional",
             "professionals",
             "resource",
             "training"
          ]
       ],
       [
          "Tram Driver",
          [
             "automobile",
             "bus",
             "driver",
             "drivers",
             "machinery",
             "operators",
             "rail",
             "road",
             "train",
             "tram"
          ]
       ],
       [
          "Translator",
          [
             "legal",
             "professionals",
             "social",
             "translator",
             "welfare"
          ]
       ],
       [
          "Transport Company Manager",
          [
             "company",
             "hospitality",
             "manager",
             "managers",
             "miscellaneous",
             "retail",
             "service",
             "services",
             "transport"
          ]
       ],
       [
          "Transport Conductor",
          [
             "conductor",
             "miscellaneous",
             "sales",
             "salespersons",
             "support",
             "ticket",
             "transport",
             "workers"
          ]
       ],
       [
          "Transport Engineer",
          [
             "civil",
             "design",
             "engineer",
             "engineering",
             "professionals",
             "science",
             "transport"
          ]
       ],
       [
          "Transport Operations Inspector",
          [
             "administrative",
             "clerical",
             "inspector",
             "inspectors",
             "miscellaneous",
             "officers",
             "operations",
             "other",
             "regulatory",
             "transport",
             "workers"
          ]
       ],
       [
          "Travel Agency Manager",
          [
             "agency",
             "hospitality",
             "manager",
             "managers",
             "retail",
             "service",
             "travel"
          ]
       ],
       [
          "Travel Attendants",
          [
             "attendants",
             "community",
             "personal",
             "service",
             "sports",
             "travel",
             "workers"
          ]
       ],
       [
          "Travel Consultant",
          [
             "advisers",
             "community",
             "consultant",
             "personal",
             "service",
             "sports",
             "tourism",
             "travel",
             "workers"
          ]
       ],
       [
          "Tree Faller",
          [
             "faller",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "logging",
             "tree",
             "workers"
          ]
       ],
       [
          "Trekking Guide",
          [
             "adventure",
             "community",
             "fitness",
             "guide",
             "guides",
             "outdoor",
             "personal",
             "service",
             "sports",
             "trekking",
             "workers"
          ]
       ],
       [
          "Tribunal Member",
          [
             "judicial",
             "legal",
             "member",
             "other",
             "professionals",
             "social",
             "tribunal",
             "welfare"
          ]
       ],
       [
          "Trolley Collector",
          [
             "collector",
             "labourers",
             "miscellaneous",
             "other",
             "trolley"
          ]
       ],
       [
          "Truck Driver (General)",
          [
             "driver",
             "drivers",
             "general",
             "machinery",
             "operators",
             "rail",
             "road",
             "truck"
          ]
       ],
       [
          "Truck Driver's Offsider",
          [
             "drivers",
             "fillers",
             "freight",
             "furniture",
             "handlers",
             "labourers",
             "offsider",
             "other",
             "shelf",
             "truck"
          ]
       ],
       [
          "Trust Officer",
          [
             "administrative",
             "clerical",
             "clerks",
             "court",
             "legal",
             "miscellaneous",
             "officer",
             "other",
             "trust",
             "workers"
          ]
       ],
       [
          "Turf Grower",
          [
             "crop",
             "farm",
             "farmers",
             "grower",
             "managers",
             "turf"
          ]
       ],
       [
          "Tyre Fitter",
          [
             "accessories",
             "fitter",
             "fitters",
             "labourers",
             "miscellaneous",
             "motor",
             "other",
             "parts",
             "tyre",
             "vehicle"
          ]
       ],
       [
          "University Lecturer",
          [
             "education",
             "lecturer",
             "lecturers",
             "professionals",
             "teachers",
             "tertiary",
             "tutors",
             "university"
          ]
       ],
       [
          "University Tutor",
          [
             "education",
             "lecturers",
             "professionals",
             "teachers",
             "tertiary",
             "tutor",
             "tutors",
             "university"
          ]
       ],
       [
          "Upholsterer",
          [
             "clothing",
             "footwear",
             "other",
             "technicians",
             "textile",
             "trades",
             "upholsterer",
             "workers"
          ]
       ],
       [
          "Urban and Regional Planner",
          [
             "architects",
             "design",
             "designers",
             "engineering",
             "planner",
             "planners",
             "professionals",
             "regional",
             "science",
             "surveyors",
             "transport",
             "urban"
          ]
       ],
       [
          "Urologist",
          [
             "health",
             "medical",
             "practitioners",
             "professionals",
             "surgeons",
             "urologist"
          ]
       ],
       [
          "Valuer",
          [
             "business",
             "economists",
             "human",
             "information",
             "land",
             "marketing",
             "organisation",
             "professionals",
             "resource",
             "valuers"
          ]
       ],
       [
          "Vascular Surgeon",
          [
             "health",
             "medical",
             "practitioners",
             "professionals",
             "surgeon",
             "surgeons",
             "vascular"
          ]
       ],
       [
          "Vegetable Farm Worker",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "vegetable",
             "workers"
          ]
       ],
       [
          "Vegetable Grower",
          [
             "crop",
             "farm",
             "farmers",
             "grower",
             "managers",
             "vegetable"
          ]
       ],
       [
          "Vegetable Picker",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "picker",
             "vegetable",
             "workers"
          ]
       ],
       [
          "Vehicle Body Builder",
          [
             "automotive",
             "body",
             "builder",
             "builders",
             "engineering",
             "painters",
             "panelbeaters",
             "technicians",
             "trades",
             "trimmers",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Vehicle Painter",
          [
             "automotive",
             "body",
             "builders",
             "engineering",
             "painter",
             "painters",
             "panelbeaters",
             "technicians",
             "trades",
             "trimmers",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Vehicle Trimmer",
          [
             "automotive",
             "body",
             "builders",
             "engineering",
             "painters",
             "panelbeaters",
             "technicians",
             "trades",
             "trimmer",
             "trimmers",
             "vehicle",
             "workers"
          ]
       ],
       [
          "Vending Machine Attendant",
          [
             "attendant",
             "labourers",
             "machine",
             "miscellaneous",
             "other",
             "vending"
          ]
       ],
       [
          "Veterinarian",
          [
             "design",
             "engineering",
             "natural",
             "physical",
             "professionals",
             "science",
             "transport",
             "veterinarian"
          ]
       ],
       [
          "Veterinary Nurse",
          [
             "animal",
             "attendants",
             "horticultural",
             "nurse",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainers",
             "veterinary",
             "workers"
          ]
       ],
       [
          "Video Producer",
          [
             "arts",
             "directors",
             "film",
             "media",
             "producer",
             "professionals",
             "radio",
             "stage",
             "television",
             "video"
          ]
       ],
       [
          "Vineyard Worker",
          [
             "crop",
             "farm",
             "forestry",
             "garden",
             "labourers",
             "vineyard",
             "workers"
          ]
       ],
       [
          "Visual Arts and Crafts Professionals",
          [
             "arts",
             "crafts",
             "media",
             "professionals",
             "visual"
          ]
       ],
       [
          "Visual Merchandiser",
          [
             "merchandiser",
             "miscellaneous",
             "sales",
             "support",
             "visual",
             "workers"
          ]
       ],
       [
          "Vocational Education Teacher",
          [
             "education",
             "professionals",
             "teacher",
             "teachers",
             "tertiary",
             "vocational"
          ]
       ],
       [
          "Waiter",
          [
             "community",
             "hospitality",
             "personal",
             "service",
             "waiter",
             "workers"
          ]
       ],
       [
          "Wall and Floor Tiler",
          [
             "construction",
             "floor",
             "glaziers",
             "plasterers",
             "technicians",
             "tiler",
             "tilers",
             "trades",
             "wall",
             "workers"
          ]
       ],
       [
          "Warehouse Administrator",
          [
             "administrative",
             "administrator",
             "clerical",
             "clerks",
             "logistics",
             "other",
             "purchasing",
             "supply",
             "warehouse",
             "workers"
          ]
       ],
       [
          "Waste Water or Water Plant Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "stationary",
             "waste",
             "water"
          ]
       ],
       [
          "Watch and Clock Maker and Repairer",
          [
             "automotive",
             "clock",
             "engineering",
             "maker",
             "mechanical",
             "metal",
             "precision",
             "repairer",
             "technicians",
             "trades",
             "watch",
             "workers"
          ]
       ],
       [
          "Water Inspector",
          [
             "administrative",
             "clerical",
             "inspector",
             "inspectors",
             "miscellaneous",
             "officers",
             "other",
             "regulatory",
             "water",
             "workers"
          ]
       ],
       [
          "Waterside Worker",
          [
             "fillers",
             "freight",
             "furniture",
             "handlers",
             "labourers",
             "other",
             "shelf",
             "waterside",
             "worker"
          ]
       ],
       [
          "Weaving Machine Operator",
          [
             "drivers",
             "footwear",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "production",
             "stationary",
             "textile",
             "weaving"
          ]
       ],
       [
          "Web Administrator",
          [
             "administrator",
             "engineering",
             "ict",
             "science",
             "support",
             "technicians",
             "telecommunications",
             "trades",
             "web",
             "workers"
          ]
       ],
       [
          "Web Designer",
          [
             "architects",
             "design",
             "designer",
             "designers",
             "engineering",
             "graphic",
             "illustrators",
             "planners",
             "professionals",
             "science",
             "surveyors",
             "transport",
             "web"
          ]
       ],
       [
          "Web Developer",
          [
             "analysts",
             "business",
             "developer",
             "developers",
             "ict",
             "multimedia",
             "professionals",
             "programmers",
             "specialists",
             "systems",
             "web"
          ]
       ],
       [
          "Weighbridge Operator",
          [
             "drivers",
             "machine",
             "machinery",
             "operator",
             "operators",
             "other",
             "plant",
             "stationary",
             "weighbridge"
          ]
       ],
       [
          "Weight Loss Consultant",
          [
             "care",
             "community",
             "consultant",
             "consultants",
             "loss",
             "personal",
             "service",
             "sports",
             "travel",
             "weight",
             "workers"
          ]
       ],
       [
          "Welder (First Class)",
          [
             "automotive",
             "class",
             "engineering",
             "fabrication",
             "first",
             "steel",
             "structural",
             "technicians",
             "trades",
             "welder",
             "welding",
             "workers"
          ]
       ],
       [
          "Welfare Centre Manager",
          [
             "centre",
             "education",
             "health",
             "manager",
             "managers",
             "services",
             "specialist",
             "welfare"
          ]
       ],
       [
          "Welfare Worker",
          [
             "arts",
             "community",
             "legal",
             "professionals",
             "recreation",
             "social",
             "welfare",
             "workers"
          ]
       ],
       [
          "Whitewater Rafting Guide",
          [
             "adventure",
             "community",
             "fitness",
             "guide",
             "guides",
             "outdoor",
             "personal",
             "rafting",
             "service",
             "sports",
             "whitewater",
             "workers"
          ]
       ],
       [
          "Wholesaler",
          [
             "construction",
             "distribution",
             "exporters",
             "importers",
             "managers",
             "production",
             "specialist",
             "wholesalers"
          ]
       ],
       [
          "Window Cleaner",
          [
             "cleaner",
             "cleaners",
             "labourers",
             "laundry",
             "other",
             "window",
             "workers"
          ]
       ],
       [
          "Wine Maker",
          [
             "chemists",
             "design",
             "engineering",
             "food",
             "maker",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport",
             "wine"
          ]
       ],
       [
          "Winery Cellar Hand",
          [
             "cellar",
             "drink",
             "factory",
             "food",
             "hand",
             "labourers",
             "process",
             "winery",
             "workers"
          ]
       ],
       [
          "Wood Machinist",
          [
             "machinist",
             "machinists",
             "other",
             "technicians",
             "trades",
             "wood",
             "workers"
          ]
       ],
       [
          "Wood Machinists and Other Wood Trades Workers",
          [
             "machinists",
             "other",
             "technicians",
             "trades",
             "wood",
             "workers"
          ]
       ],
       [
          "Wood Turner",
          [
             "machinists",
             "other",
             "technicians",
             "trades",
             "turner",
             "wood",
             "workers"
          ]
       ],
       [
          "Wood and Wood Products Factory Worker",
          [
             "factory",
             "labourers",
             "miscellaneous",
             "process",
             "products",
             "timber",
             "wood",
             "workers"
          ]
       ],
       [
          "Wool Buyer",
          [
             "buyer",
             "buyers",
             "miscellaneous",
             "retail",
             "sales",
             "support",
             "wool",
             "workers"
          ]
       ],
       [
          "Wool Classer",
          [
             "classer",
             "miscellaneous",
             "other",
             "technicians",
             "trades",
             "wool",
             "workers"
          ]
       ],
       [
          "Wool Handler",
          [
             "farm",
             "forestry",
             "garden",
             "handler",
             "labourers",
             "livestock",
             "wool",
             "workers"
          ]
       ],
       [
          "Word Processing Operator",
          [
             "administrative",
             "clerical",
             "general",
             "keyboard",
             "operator",
             "operators",
             "processing",
             "word",
             "workers"
          ]
       ],
       [
          "Workplace Relations Adviser",
          [
             "adviser",
             "business",
             "human",
             "marketing",
             "professionals",
             "relations",
             "resource",
             "training",
             "workplace"
          ]
       ],
       [
          "Yarn Carding and Spinning Machine Operator",
          [
             "carding",
             "drivers",
             "footwear",
             "machine",
             "machinery",
             "operator",
             "operators",
             "plant",
             "production",
             "spinning",
             "stationary",
             "textile",
             "yarn"
          ]
       ],
       [
          "Youth Worker",
          [
             "community",
             "health",
             "personal",
             "service",
             "support",
             "welfare",
             "worker",
             "workers",
             "youth"
          ]
       ],
       [
          "Zookeeper",
          [
             "animal",
             "attendants",
             "horticultural",
             "shearers",
             "skilled",
             "technicians",
             "trades",
             "trainers",
             "workers",
             "zookeeper"
          ]
       ],
       [
          "Zoologist",
          [
             "design",
             "engineering",
             "life",
             "natural",
             "physical",
             "professionals",
             "science",
             "scientists",
             "transport",
             "zoologist"
          ]
       ]
    ];
    return { occupations: occupations };
});
