// All the static trips data.
// Note: the ids stay the same - the labels can be changed.
angular.module('tmr').service('TripData', ['RouteData', 'Tenant',
function (RouteData, Tenant) {

    let self = this;

    const stopTooltip = "This information will not be able to traced back to you – anything that may identify individuals or households will be deleted from our survey records.\n We are bound by the Information Privacy Act 2009 to handle the information you provide in a way which protects your privacy.";

    const group = {
        primary:    "Primary Places",
        transport:  "Transport Places",
        general:    "General Places",
        other:      "Other Places",
    };

    // hasRoutes: this option should trigger the route selectors
    // hasOther:  this option should trigger the 'please specify' box
    // mode: matches .id in self.modes
    // group: optional <opt-group> categories
    let endpoints = [
        {
            label:          "Select one",
            id:             "",
            isDefault:      true,
        },

        // Primary places
        {
            label:          "My home",
            id:             "home",
            group:          group.primary,
            reason:         "home",
            isHouse:        true,
        },
        {
            label:          "My campus",
            id:             "campus",
            group:          group.primary,
            reason:         "edu",
        },
        {
            label:          "My workplace",
            id:             "work",
            group:          group.primary,
            reason:         "workplace",
        },

        // Transport places
        {
            label:          "Public bus stop",
            id:             "bus-stop",
            group:          group.transport,
            reason:         "pt",
            hasRoutes:      true,
            hasOtherRoute:  true,
            mode:           "bus",
            prompt: {
                route:      "Which bus route is this on?",
                departure:  "Which bus stop did you leave from?",
                arrival:    "Which bus stop did you arrive at?",
            },
            tooltip: {
                route:      "This will help us provide you with suggested bus stop locations for the next question so you don’t need to look them up.",
                stop:       stopTooltip,
            },
            placeholder: {
                route:      "Select a route",
                stop:       "Select a stop",
            },
        },
        {
            label:          "School bus stop",
            id:             "schoolbus-stop",
            group:          group.transport,
            reason:         "pt",
            hasRoutes:      true,
            hasOtherRoute:  true,
            mode:           "schoolbus",
            prompt: {
                route:      "Which bus route is this on?",
                departure:  "Which bus stop did you leave from?",
                arrival:    "Which bus stop did you arrive at?",
            },
            tooltip: {
                route:      "This will help us provide you with suggested bus stop locations for the next question so you don’t need to look them up.",
                stop:       stopTooltip,
            },
            placeholder: {
                route:      "Select a route",
                stop:       "Select a stop",
            },
        },
        {
            label:          "Car park",
            id:             "car-park",
            group:          group.transport,
            reason:         "park",
        },
        {
            label:          "Ferry terminal",
            id:             "ferry-terminal",
            group:          group.transport,
            reason:         "pt",
            hasRoutes:      true,
            mode:           "ferry",
            prompt: {
                route:      "Which ferry service is this on?",
                departure:  "Which ferry terminal did you leave from?",
                arrival:    "Which ferry terminal did you arrive at?",
            },
            tooltip: {
                route:      "This will help us provide you with suggested ferry terminals for the next question so you don’t need to look them up.",
                stop:       stopTooltip,
            },
            placeholder: {
                stop:       "Select a terminal",
            },
        },
        {
            label:          "Light rail stop",
            id:             "light-rail-stop",
            group:          group.transport,
            reason:         "pt",
            hasRoutes:      true,
            mode:           "lightrail",
            prompt: {
                route:      "Which light rail route is this on?",
                departure:  "Which light rail stop did you leave from?",
                arrival:    "Which light rail stop did you arrive at?",
            },
            tooltip: {
                route:      "This will help us provide you with suggested light rail stop locations for the next question so you don’t need to look them up.",
                stop:       stopTooltip,
            },
            placeholder: {
                stop:       "Select a stop",
            },
        },
        {
            label:          "Train station",
            id:             "train-station",
            group:          group.transport,
            reason:         "pt",
            hasRoutes:      true,
            mode:           "train",
            prompt: {
                route:      "Which train line is this on?",
                departure:  "Which train station did you leave from?",
                arrival:    "Which train station did you arrive at?",
            },
            tooltip: {
                route:      "This will help us provide you with suggested train stations for the next question so you don’t need to look them up.",
                stop:       stopTooltip,
            },
            placeholder: {
                stop:       "Select a station",
            },
        },

        // General places
        {
            label:      "Childcare or " + (Tenant.isNSW ? "pre-school" : "kindergarten"),
            id:         "preschool",
            group:      group.general,
            reason:     "transport",
        },
        {
            label:        "Petrol station",
            id:         "petrol-station",
            group:      group.general,
        },
        {
            label:        "Primary or secondary school",
            id:         "school",
            group:      group.general,
        },
        {
            label:        "Restaurant, café, or food outlet",
            id:         "food",
            group:      group.general,
        },
        {
            label:        "Shop or supermarket",
            id:         "shop",
            group:      group.general,
        },
        {
            label:        "Someone else's home",
            id:         "other-home",
            group:      group.general,
            isHouse:    true,
        },

        // Other places
        {
            label:        "Other study-related place",
            id:         "other-study",
            group:      group.other,
        },
        {
            label:        "Other work-related place",
            id:         "other-work",
            group:      group.other,
        },
        {
            label:      "Other place (please specify)",
            id:         "other",
            group:      group.other,
            hasOther:   true,
        },
    ];

    // For all the endpoints marked as hasRoutes, check if that mode exists in
    // this region's route data. If it doesn't, don't show it in the selector.
    self.endpoints = _.reject(endpoints, endpoint =>
                              endpoint.hasRoutes && !RouteData[endpoint.mode]);

    // hasOther:  this option should trigger the 'please specify' box
    let modes = [
        {
            label:          "Select one",
            id:             "",
            isDefault:      true,
        },
        {
            label:          "Walking",
            id:             "walk",
        },
        {
            label:          "Bicycle",
            id:             "bike",
        },
        {
            label:          "Train",
            id:             "train",
            hasRoutes:      true,
        },
        {
            label:          "Light rail",
            id:             "lightrail",
            hasRoutes:      true,
        },
        {
            label:          "School bus (with route number)",
            id:             "schoolbus",
            hasRoutes:      true,
        },
        {
            label:          "School bus (private/chartered)",
            id:             "privateschoolbus",
        },
        {
            label:          "Public bus",
            id:             "bus",
            hasRoutes:      true,
        },
        {
            label:          "Ferry",
            id:             "ferry",
            hasRoutes:      true,
        },
        {
            label:          "Charter/Courtesy/Other bus",
            id:             "otherbus",
        },
        {
            label:          "Car or passenger vehicle",
            id:             "car",
            hasDriver:      true,
        },
        {
            label:          "Motorcycle or scooter",
            id:             "motorbike",
            hasDriver:      true,
        },
        {
            label:          "E-bike or E-scooter",
            id:             "e-bike",
        },
        {
            label:          "Goods van or truck",
            id:             "van",
            hasDriver:      true,
        },
        {
            label:          "Taxi",
            id:             "taxi",
        },
        {
            label:          "Uber / Other Ride Share",
            id:             "ride-share",
        },
        {
            label:          "Mobility scooter",
            id:             "scooter",
        },
        {
            label:          "Other method",
            id:             "other",
            hasOther:       true,
        },
    ];

    // For all the modes marked as hasRoutes, check if that mode exists in
    // this region's route data. If it doesn't, don't show it in the selector.
    console.log("Filtering travel modes from routedata");
    self.modes = _.reject(modes, mode => mode.hasRoutes && !RouteData[mode.id]);

    self.carpark = [
        {
            label:          "Select one",
            id:             "",
            isDefault:      true,
        },
        {
            label:          "Vehicle not parked",
            id:             "nowhere",
        },
        {
            label:          "Residential property",
            id:             "home",
        },
        {
            label:          "On the street",
            id:             "street",
        },
        {
            label:          "Car park (off-street)",
            id:             "carpark",
        },
    ];

    // Link up the modes and the endpoints. If mode.id == endpoint.mode then
    // set mode.endpoint to that endpoint. This lets us do things like set the
    // endpoints to be train stations if the mode is a train trip.
    _.each(self.modes, mode => {
        var endpoint = _.findWhere(self.endpoints, { mode: mode.id });
        if (endpoint) {
            mode.endpoint = endpoint;
        }
    });

    self.fixedEndpoints = _.where(self.endpoints, { hasRoutes: true });

    self.reasons = [
        {
            label:          "Select one",
            id:             "",
            isDefault:      true,
        },
        {
            label:          "Get on/off public transport",
            id:             "pt",
        },
        {
            label:          "Refuel my vehicle",
            id:             "refuel",
        },
        {
            label:          "Work (my workplace)",
            id:             "workplace",
        },
        {
            label:          "Work (other work reason)",
            id:             "workother",
        },
        {
            label:          "Education",
            id:             "edu",
        },
        {
            label:          "Social Visit",
            id:             "social",
        },
        {
            label:          "Recreation/leisure activity",
            id:             "rec",
        },
        {
            label:          "Eat/drink",
            id:             "eat",
        },
        {
            label:          "Shopping",
            id:             "shop",
        },
        {
            label:          "Personal business",
            id:             "personal",
        },
        {
            label:          "Pickup/deliver something (not work-related)",
            id:             "pickup",
        },
        {
            label:          "Pick up or drop off a passenger",
            id:             "transport",
        },
        {
            label:          "Park / Pick-up a Vehicle",
            id:             "park",
        },
        {
            label:          "Just accompanying someone",
            id:             "accompany",
        },
        {
            label:          "Go home",
            id:             "home",
        },
        {
            label:          "Other reason",
            id:             "other",
            hasOther:       true,
        },
    ];

}]);
